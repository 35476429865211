import React, { CSSProperties } from "react";
import styled from "styled-components";
// import { StyledUl } from "../styles/StyledUl";
// import { menuClasses } from "../utils/utilityClasses";
// import { useMenu } from "../hooks/useMenu";

interface StyledSubMenuContentProps extends React.HTMLAttributes<HTMLDivElement> {
  transitionDuration?: number;
  open?: boolean;
  openWhenCollapsed?: boolean;
  firstLevel?: boolean;
  collapsed?: boolean;
  defaultOpen?: boolean;
  rootStyles?: CSSProperties;
  children?: React.ReactNode;
  backgroundColor?: string;
}

const StyledSubMenuContent = styled.div<StyledSubMenuContentProps>`
  display: none;
  overflow: hidden;
  z-index: 999;
  transition: height ${({ transitionDuration }) => transitionDuration}ms;
  box-sizing: border-box;

  ${({ firstLevel, collapsed }) =>
    firstLevel &&
    collapsed &&
    `
     background-color: white;
     box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
     `}

  ${({ defaultOpen }) => defaultOpen && "height: auto;display: block;"}

  ${({ collapsed, firstLevel, openWhenCollapsed }) =>
    collapsed && firstLevel
      ? `
      position: fixed;
      padding-left: 0px;
      width: 200px;
      border-radius: 4px;
      height: auto!important;
      display: block!important;     
      transition: none!important;     
      visibility: ${openWhenCollapsed ? "visible" : "hidden"};
     `
      : `
      position: static!important;
      transform: none!important;
      `};};
`;

const StyledSubMenuContentFR: React.ForwardRefRenderFunction<
  HTMLDivElement,
  StyledSubMenuContentProps
> = (
  {
    children,
    open,
    openWhenCollapsed,
    firstLevel,
    collapsed,
    defaultOpen,
    backgroundColor,
    ...rest
  },
  ref
) => {
  const [defaultOpenState] = React.useState(defaultOpen);

  return (
    <StyledSubMenuContent
      ref={ref}
      firstLevel={firstLevel}
      collapsed={collapsed}
      open={open}
      openWhenCollapsed={openWhenCollapsed}
      transitionDuration={3}
      defaultOpen={defaultOpenState}
      {...rest}
    >
      {children}
    </StyledSubMenuContent>
  );
};

export const SubMenuContent = React.forwardRef(StyledSubMenuContentFR);
