import React from "react";
import classNames from "classnames";
import { NavLink as NavLinkRouter } from "react-router-dom";
import CDBLink from "../Link";
import { Component } from "./TabLink.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props{
  children?: React.ReactNode,
  className?: any,
  active?: boolean | any,
  text?: string,
  link?: boolean,
  to?: string,
  tag?: string,
  onClick?: React.MouseEventHandler<HTMLDivElement>
  role?: string
}

const TabLink = (props: Props) => {
  const {
    children,
    className,
    active,
    text,
    link,
    to,
    tag,
    ...attributes
  } = props;
  const Tag = link ? CDBLink : NavLinkRouter;
  const itemClasses = classNames(
    "nav-item",
    active && "active",
    text && "navbar-text",
    className
  );
  const linkClasses = classNames("tab-link", active && "active", className);

  return (
    <ThemeProvider theme={theme}>
    <Component
      data-test="nav-item"
      as={tag as unknown as undefined}
      {...attributes}
      className={itemClasses}
    >
      <Tag to={to} className={linkClasses}>{children}</Tag>
    </Component>
    </ThemeProvider>
  );
};
export default TabLink;

export { TabLink as CDBTabLink };
