import React from 'react';
import { createPopper } from '@popperjs/core';
import { SidebarContext } from "../Sidebar"
import ResizeObserver from "resize-observer-polyfill"

interface PopperOptions {
  referenceElement: React.RefObject<HTMLDivElement>;
  popperElement: React.RefObject<HTMLDivElement>;
  firstChild?: number;
}

interface PopperResult {
  popperInstance?: ReturnType<typeof createPopper>;
}

export const usePopper = (options: PopperOptions): PopperResult => {
  const { referenceElement, popperElement, firstChild } = options;

  const { toggled } = React.useContext(SidebarContext);
  const popperInstanceRef = React.useRef<ReturnType<typeof createPopper> | undefined>();

  React.useEffect(() => {
    if (firstChild && toggled && popperElement.current && referenceElement.current) {
      popperInstanceRef.current = createPopper(referenceElement.current, popperElement.current, {
        placement: 'right',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ],
      });
    }

    return () => popperInstanceRef.current?.destroy();
  }, [toggled, popperElement, referenceElement, firstChild]);

  /**
   * update popper instance (position) when referenceElement or popperElement changes
   */
  React.useEffect(() => {
    if (popperElement.current && referenceElement.current) {
      const ro = new ResizeObserver(() => {
        popperInstanceRef.current?.update();
      });

      ro.observe(popperElement.current);
      ro.observe(referenceElement.current);
    }

    setTimeout(() => {
      popperInstanceRef.current?.update();
    }, 3);
  }, [ toggled, popperElement, referenceElement]);

  return { popperInstance: popperInstanceRef.current };
};