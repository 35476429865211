import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./PanelTitle.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";

interface Props{
  className?: string,
  tag?: any,
  children?: React.ReactNode
}

const PanelTitle = (props: Props) => {
  const {
    className,
    tag,
    children,
    ...attributes
  } = props;

  const panelTitleClasses = classNames(className);

  let panelTitleComponent = (
    <ThemeProvider theme={theme}>
      <Component {...attributes} as={tag} className={panelTitleClasses}>
        {children}
      </Component>
    </ThemeProvider>
  );

  return panelTitleComponent;
};

PanelTitle.propTypes = {
  className: PropTypes.string,
};

PanelTitle.defaultProps = {
  tag: "h6",
};

export default PanelTitle;
export { PanelTitle as CDBPanelTitle };
