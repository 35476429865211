import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  getColorStyles,
  style as commonStyles,
  Component as StyledComponent,
} from "./TimePicker.style";
import {
  appendZero,
  getFormat12,
  format12to24,
  format24to12,
  isMousePressed,
} from "./utils";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props {
  time?: string;
  theme?: string;
  color1?: string;
  headerColor?: string;
  placeholder?: string;
  className?: string;
  style?: any;
  degree?: string;
  colorPalette?: string;
}

type StateType = {
  toShow: boolean;
  time: {
    format12: string;
    format24: string;
  };
  degree: number;
  hour: number;
  minute: number;
  toShowHourContainer: boolean;
  selectedIndexDegree: number;
  isAmSelected: boolean;
};

const TimePicker = (props: Props) => {
  const {
    time,
    color1,
    headerColor,
    placeholder,
    className,
    style,
    degree,
    ...attributes
  } = props;

  let _format12 = "";
  if (time) {
    const timeArr = time.split(":");
    _format12 = format24to12(Number(timeArr[0]), Number(timeArr[1]));
  }

  type Pos = { x: number; y: number };
  const [center, setCenter] = useState<Pos>({
    x: 0,
    y: 0,
  });
  const [containerPos, setContainerPos] = useState<Pos>({
    x: 0,
    y: 0,
  });
  const [basePoint, setBasePoint] = useState<Pos>({
    x: 0,
    y: 0,
  });

  const mask = useRef<HTMLDivElement>(null);
  const closeButton = useRef<HTMLButtonElement>(null);
  const inputField = useRef<HTMLInputElement>(null);

  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState<StateType>({
    toShow: false,
    time: {
      format12: _format12,
      format24: "",
    },
    degree: 0,
    hour: 0,
    minute: 0,
    toShowHourContainer: false,
    selectedIndexDegree: 0,
    isAmSelected: false,
  });

  if (!mounted) {
    addStyles();
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    let format12 = "";
    let format24 = "";

    const { time } = props;

    if (time) {
      let temp = time.split(":");
      format12 = format24to12(Number(temp[0]), Number(temp[1]));
      format24 = time;
    }

    setState((prevState) => ({
      ...prevState,
      time: {
        format12,
        format24,
      },
    }));
  }, [props.time]);

  function addStyles() {
    const commonSelector = "react-timepicker-common-style";
    const themeSelector = getThemeSelector(props.theme!, props.color1!);
    const { theme, color1, headerColor } = props;
    let head = document.head || document.getElementsByTagName("head")[0];

    if (!document.getElementById(commonSelector)) {
      let style = document.createElement("style");
      style.type = "text/css";
      style.id = commonSelector;

      if (style.sheet) {
        //style.styleSheet
        //style.styleSheet.cssText = commonStyles;
        style.sheet.insertRule(commonStyles);
      } else {
        style.appendChild(document.createTextNode(commonStyles));
      }

      head.appendChild(style);
    }

    if (!document.getElementById(themeSelector)) {
      let themeStyles = getColorStyles({
        themeSelector,
        theme,
        color1,
        headerColor,
      });

      let style = document.createElement("style");
      style.type = "text/css";
      style.id = themeSelector;

      if (style.sheet) {
        style.sheet.insertRule(themeStyles);
      } else {
        style.appendChild(document.createTextNode(themeStyles));
      }
      head.appendChild(style);
    }
  }

  // utils
  function removeEventListener() {
    window.removeEventListener("keydown", handleKeyPress);
  }

  function addEventListener() {
    window.addEventListener("keydown", handleKeyPress);
  }

  // handlers

  const handleKeyPress = (e: any) => {
    switch (e.which) {
      case 13:
        if (e.target !== closeButton.current) {
          handleSet();
        }
        break;

      case 27:
        toggleToShow(false);
        break;

      case 38: // up
        state.toShowHourContainer
          ? setHour((state.degree + 30) % 360 || 360)
          : setMinute((state.degree + 6) % 360 || 360);
        break;

      case 40: // down
        state.toShowHourContainer
          ? setHour((state.degree - 30) % 360 || 360)
          : setMinute((state.degree - 6) % 360 || 360);
        break;

      default:
    }
  };

  const handleMove = (event: any) => {
    event.preventDefault();
    if (isMousePressed(event)) return;
    changeClock(event.nativeEvent.clientX, event.nativeEvent.clientY);
  };

  const handleTouchMove = (event: any) => {
    event.preventDefault();
    changeClock(
      event.changedTouches[0].clientX,
      event.changedTouches[0].clientY
    );
  };

  const handleTouchUp = (event) => {
    if (event.target === mask.current) return;
    changeClock(
      event.changedTouches[0].clientX,
      event.changedTouches[0].clientY
    );
  };

  const handleMoveUp = (event) => {
    if (event.target === mask.current) return;
    changeClock(event.nativeEvent.clientX, event.nativeEvent.clientY);
  };

  const changeClock = (clientX, clientY) => {
    const x = clientX - containerPos.x;
    const y = clientY - containerPos.y;
    state.toShowHourContainer
      ? setHour(getDegree(x, y))
      : setMinute(getDegree(x, y));
  };

  const toggleHourOrMinuteContainer = (toShowHourContainer: boolean) => {
    let { degree, selectedIndexDegree } = toShowHourContainer
      ? getSelectedIndexDegreeAndDegreeForHour(Number(state.hour))
      : getSelectedIndexDegreeAndDegreeForMinute(Number(state.minute));

    console.log({ degree }, { selectedIndexDegree }, { toShowHourContainer })
    setState((prevState) => ({
      ...prevState,
      toShowHourContainer,
      degree,
      selectedIndexDegree,
    }));
  };

  const toggleAmPm = (isAmSelected: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isAmSelected,
    }));
  };

  const handleFocus = (e) => {
    e.preventDefault();
    let format24 = props.time;
    let { hour, minute, degree, selectedIndexDegree, isAmSelected } =
      getInitialConfig(format24);
    // hour = appendZero(Math.round(degree / 30) || '12'); //not sure why i added this
    toggleToShow();

    const _hour = parseInt(hour);
    const _minute = parseInt(minute);

    Promise.resolve()
      .then(() =>
        setState((prevState) => ({
          ...prevState,
          degree,
          hour: _hour,
          minute: _minute,
          toShowHourContainer: true,
          selectedIndexDegree,
          isAmSelected,
        }))
      )
      .then(() => {
        init();
        console.log("From focus:", { containerPos }, { center }, { basePoint });
      });
  };

  function toggleToShow(toShow = true) {
    setState((prevState) => ({
      ...prevState,
      toShow,
    }));
    toShow ? addEventListener() : removeEventListener();
  }

  const handleSet = () => {
    let allFormat = getTime(
      Number(state.hour),
      Number(state.minute),
      state.isAmSelected
    );
    setState((prevState) => ({
      ...prevState,
      time: allFormat,
    }));
    toggleToShow(false);
    inputField.current!.blur();
  };

  // functionality
  function init() {
    setCenter({
      x: 130,
      y: 130,
    });

    const maskPosition = mask.current!.getBoundingClientRect();
    setContainerPos({
      y: maskPosition.top,
      x: maskPosition.left,
    });

    setBasePoint({
      x: 130,
      y: 0,
    })

  }

  function getThemeSelector(theme: string, color1: string) {
    let _color1 = color1;
    if (color1 && color1.indexOf("#") === 0) {
      _color1 = color1.substr(1);
    }

    if (theme) {
      if (color1) {
        return theme.split(" ").join("-") + _color1;
      }
      return theme.split(" ").join("-");
    }

    if (_color1) {
      return _color1;
    }

    return "react-time-picker-theme";
  }

  function getHour(val) {
    return appendZero(val % 12 || "12");
  }

  function getMinute(val) {
    return appendZero(val % 60 || "0");
  }

  function getInitialConfig(time) {
    let date = new Date();
    time = time ? time : date.getHours() + ":" + date.getMinutes();
    const temp = time.split(":");
    const hour24 = Number(temp[0]);
    const minute24 = Number(temp[1]);
    const { degree, selectedIndexDegree } =
      getSelectedIndexDegreeAndDegreeForHour(hour24);

    return {
      hour: getHour(hour24),
      minute: getMinute(minute24),
      degree,
      selectedIndexDegree,
      isAmSelected: Number(temp[0]) <= 12,
    };
  }

  function getSelectedIndexDegreeAndDegreeForHour(val) {
    const degree = (val * 30) % 360;
    return {
      selectedIndexDegree: getSelectedIndexDegree(degree),
      degree,
    };
  }

  function getSelectedIndexDegreeAndDegreeForMinute(val) {
    const degree = (val * 6) % 360;
    return {
      selectedIndexDegree: getSelectedIndexDegree(degree),
      degree,
    };
  }

  function getSelectedIndexDegree(degree) {
    return (degree / 30) % 12;
  }

  function getDegree(offsetX, offsetY) {
    const x = offsetX - center.x;
    const y = offsetY - center.y;
    const cx = basePoint.x - center.x;
    const cy = basePoint.y - center.y;
    const atan = Math.atan2(cx, cy) - Math.atan2(x, y);
    return (atan * 57.29577951308232) % 360;
  }

  function getClock(className) {
    const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    const hoursOrMinutes = state.toShowHourContainer ? hours : minutes;
    return hoursOrMinutes.map((i, j) => {
      return (
        <span
          key={i}
          className={`circle ${state.selectedIndexDegree === j ? `selected ${className}` : ""
            }`}
        >
          {i}
        </span>
      );
    });
  }

  function getTime(hour, minute, isAmSelected) {
    const format12 = getFormat12(hour, minute, isAmSelected);
    const format24 = format12to24(hour, minute, isAmSelected);
    return {
      format12,
      format24,
    };
  }

  function setMinute(degree: number) {
    const base = Math.round(degree / 6);
    degree = base * 6;
    const minute = parseInt(getMinute(base));
    const selectedIndexDegree = getSelectedIndexDegree(degree);
    const toReturn = {
      degree,
      minute,
      selectedIndexDegree,
    };
    setState((prevState) => ({
      ...prevState,
      ...toReturn,
    }));
    return toReturn;
  }

  function setHour(degree: number) {
    const base = Math.round(degree / 30);
    degree = base * 30;
    const hour = parseInt(getHour(base));
    const selectedIndexDegree = getSelectedIndexDegree(degree);
    const toReturn = {
      degree,
      hour,
      selectedIndexDegree,
    };
    setState((prevState) => ({
      ...prevState,
      ...toReturn,
    }));
    return toReturn;
  }

  function getBody() {
    if (!state.toShow) return false;
    const themeSelector = getThemeSelector(props.theme, props.color1);
    const primaryColorColorClassName = `react-timepicker-primary-color-color-${themeSelector}`;
    const primaryColorBackgroundClassName = `react-timepicker-primary-color-background-${themeSelector}`;
    const backgroundColorClassName = `react-timepicker-background-color-${themeSelector}`;
    return (
      <div>
        <div
          className="timepicker-backdrop"
          onClick={() => toggleToShow(false)}
        />
        <div className="timepicker-modal">
          <header className={`timepicker-header ${backgroundColorClassName}`}>
            <div className="timepicker-time-container">
              <span
                className={`text-shadow ${!state.toShowHourContainer ? "is-not-selected" : ""
                  }`}
                onClick={() => toggleHourOrMinuteContainer(true)}
              >
                {state.hour}
              </span>
              <span className="text-shadow">:</span>
              <span
                className={`text-shadow ${state.toShowHourContainer ? "is-not-selected" : ""
                  }`}
                onClick={() => toggleHourOrMinuteContainer(false)}
              >
                {state.minute}
              </span>
            </div>
            <div className="timepicker-am-pm-container">
              <input
                className="am-pm-input"
                type="radio"
                id="am"
                name="am-pm"
                value="am"
                onChange={() => toggleAmPm(true)}
                defaultChecked={state.isAmSelected}
              />
              <label className="am-pm-label" htmlFor="am">
                AM
              </label>
              <input
                className="am-pm-input"
                type="radio"
                id="pm"
                name="am-pm"
                value="pm"
                onChange={() => toggleAmPm(false)}
                defaultChecked={!state.isAmSelected}
              />
              <label className="am-pm-label" htmlFor="pm">
                PM
              </label>
            </div>
          </header>

          <main className="timepicker-main">
            <div
              className="hours-container"
              ref={mask}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchUp}
              onMouseMove={handleMove}
              onMouseUp={handleMoveUp}
            >
              <div
                className={`${primaryColorBackgroundClassName} hand`}
                style={{
                  transform: `rotate(${state.degree - 90}deg)`,
                  WebkitTransform: `rotate(${state.degree - 90}deg)`,
                }}
              >
                &nbsp;
              </div>
              {getClock(primaryColorBackgroundClassName)}
            </div>
          </main>
          <footer>
            <button
              type="button"
              className={`${primaryColorColorClassName} timepicker-button close`}
              onClick={() => toggleToShow(false)}
              ref={closeButton}
            >
              Close
            </button>
            <button
              type="button"
              className={`${primaryColorBackgroundClassName} timepicker-button`}
              onClick={handleSet}
            >
              Set
            </button>
          </footer>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledComponent className="timepicker-container" >
        <input
          readOnly
          type="text"
          placeholder={props.placeholder}
          className={props.className}
          value={state.time.format12}
          onFocus={handleFocus}
          ref={inputField}
          style={props.style}
        />
        {getBody()}
      </StyledComponent>
    </ThemeProvider>
  );
};
TimePicker.propTypes = {
  time: PropTypes.string,
  theme: PropTypes.string,
  color1: PropTypes.string,
  headerColor: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default TimePicker;
export { TimePicker as CDBTimePicker };
