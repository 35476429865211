import styled from "styled-components";

export const Component = styled.div`
	width:100%;
`;

export const Div = styled.div`
	padding:5px;
	display:flex;
	flex-wrap:wrap;
	cursor:pointer;
	min-height:35px;
	border:1px solid;
	align-items:center;
	box-sizing:border-box;

	span {
		font-size:0.8em;
		margin:2px;

		i {
			font-size:0.9em;
			margin:0px;
		}
	}

	form {
		flex:1;

		input{
			width:100%;
			min-width:10px;
			border:none;
			outline:none;
			font-size:0.9em;
		}
	}
`;

export const Options = styled.div`
	color:#000;
	background:#fff;
	margin-top:10px;
	max-height:0px;
	overflow-y:scroll;
	transition: all 0.3s ease-in-out;

	&.display {
		max-height:150px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	}

	::-webkit-scrollbar {
		width:3px;
	}

	::-webkit-scrollbar-thumb {
		border-radius:5px;
		background-color: #ccc;
	}

	span {
		font-size:0.9em;
	}

	div {
		padding:10px;
		cursor:pointer;
		transition: all 0.1s ease-in-out;

		:hover{
			background-color:#ddd;
		}
	}
`;
