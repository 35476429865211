import styled from "styled-components";

export const Component = styled.span`
  .tab-link {
    margin-top: 5px;
    border: none;
    trabsition: 1s;
    color: #000000;
    border-bottom: 5px solid #e2e2e2;
    font-weight: bold;
    transition:all 300ms ease-in-out;
  }
  .tab-link.active {
    border-bottom: 5px solid #000000;
  }
`;
