import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getYear, getMonthHuman, getDate } from '@wojtekmaj/date-utils'

import DayInput from './DateInput/DayInput'
import MonthInput from './DateInput/MonthInput'
import MonthSelect from './DateInput/MonthSelect'
import YearInput from './DateInput/YearInput'
import NativeInput from './DateInput/NativeInput'

import { getFormatter } from './shared/dateFormatter'
import { getBegin, getEnd } from './shared/dates'
import { isMaxDate, isMinDate } from './shared/propTypes'
import { between } from './shared/utils'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'

type ValueType = 'century'| 'decade'| 'year'|'month'|'day'

const defaultMinDate = new Date(-8.64e15)
const defaultMaxDate = new Date(8.64e15)
const allViews = ['century', 'decade', 'year', 'month']
const allValueTypes = [...allViews.slice(1), 'day']

function datesAreDifferent(date1, date2) {
  return (
    (date1 && !date2) ||
    (!date1 && date2) ||
    (date1 && date2 && date1.getTime() !== date2.getTime())
  )
}

/**
 * Returns value type that can be returned with currently applied settings.
 */
function getValueType(maxDetail) {
  return allValueTypes[allViews.indexOf(maxDetail)]
}

function getValue(value, index) {
  if (!value) {
    return null
  }

  const rawValue =
    value instanceof Array && value.length === 2 ? value[index] : value

  if (!rawValue) {
    return null
  }

  const valueDate = new Date(rawValue)

  if (isNaN(valueDate.getTime())) {
    throw new Error(`Invalid date: ${value}`)
  }

  return valueDate
}

function getDetailValue({ value, minDate, maxDate, maxDetail }, index) {
  const valuePiece = getValue(value, index)

  if (!valuePiece) {
    return null
  }

  const valueType = getValueType(maxDetail)
  const detailValueFrom = [getBegin, getEnd][index](valueType, valuePiece)

  return between(detailValueFrom, minDate, maxDate)
}

const getDetailValueFrom = (args) => getDetailValue(args, 0)

const getDetailValueTo = (args) => getDetailValue(args, 1)

const getDetailValueArray = (args) => {
  const { value } = args

  if (value instanceof Array) {
    return value
  }

  return [getDetailValueFrom, getDetailValueTo].map((fn) => fn(args))
}

function isInternalInput(element) {
  return element.getAttribute('data-input') === 'true'
}

function findInput(element, property) {
  let nextElement = element
  do {
    nextElement = nextElement[property]
  } while (nextElement && !isInternalInput(nextElement))
  return nextElement
}

function focus(element) {
  if (element) {
    element.focus()
  }
}

function _renderCustomInputs(
  getPlaceholder: string,
  elementFunctions: object,
  allowMultipleInstances: boolean
) {
  const usedFunctions: any[] = []
  const pattern = new RegExp(
    Object.keys(elementFunctions)
      .map((el) => `${el}+`)
      .join('|'),
    'g'
  )
  const matches = getPlaceholder.match(pattern)

  return getPlaceholder.split(pattern).reduce((arr: string[], element, index) => {
    const res = [...arr]
    const currentMatch = matches && matches[index]

    if (currentMatch) {
      const otherMatch = Object.keys(elementFunctions).find((elementFunction) =>
        currentMatch.match(elementFunction)
      )

      const renderFunction =
        elementFunctions[currentMatch] ||
        (otherMatch && elementFunctions[otherMatch])

      if (!allowMultipleInstances && usedFunctions.includes(renderFunction)) {
        res.push(currentMatch)
      } else {
        res.push(renderFunction(currentMatch, index))
        usedFunctions.push(renderFunction)
      }
    }
    return res
  }, [])
}

export default function DateInput(props) {
  const [state, setState] = useState({
    isCalendarOpen: null,
    value: null,
    year: null,
    month: null,
    day: null,
  })

  const {
    autoFocus,
    dayAriaLabel,
    daygetPlaceholder,
    showLeadingZeros,
    minDate, maxDate, maxDetail,
    ...attributes
  } = props

  useEffect(() => {
    
    if (props.isCalendarOpen !== state.isCalendarOpen) {
      setState(prev => ({
        ...prev,
        isCalendarOpen: props.isCalendarOpen
      }))
    }

    const nextValue = getDetailValueFrom({
      value: props.value,
      minDate,
      maxDate,
      maxDetail
    })

    const values: [any, any] = [nextValue, state.value]

    const detailValueFrom = values.map((value: any) =>
    getDetailValueFrom({
      value,
      minDate,
      maxDate,
      maxDetail
    }))

    const detailValueTo = values.map((value) =>
    getDetailValueTo({
      value,
      minDate,
      maxDate,
      maxDetail
    })
  )

    if (
      // Toggling calendar visibility resets values
      state.isCalendarOpen || // Flag was toggled
      datesAreDifferent(detailValueFrom[0], detailValueFrom[1]) ||
      datesAreDifferent(detailValueTo[0], detailValueTo[1])
    ) {
      if (nextValue) {

        setState(prev => ({
          ...prev,
          year : getYear(nextValue),
        month : getMonthHuman(nextValue),
        day : getDate(nextValue),
        }))
        
      } else {
        setState(prev => ({
          ...prev,
          year : null,
        month : null,
        day : null,
        }))
        
      }
      setState(prev => ({
        ...prev,
        value: nextValue
      }))
      
    }

  }, [props.minDate, props.maxDate, props.maxDetail])

  let dayInput: any;
  let monthInput: any;
  let yearInput: any;
  
  function  getFormatDate() {
    const { maxDetail } = props

    const options = { year: 'numeric', month: '', day: '' }
    const level = allViews.indexOf(maxDetail)
    if (level >= 2) {
      options.month = 'numeric'
    }
    if (level >= 3) {
      options.day = 'numeric'
    }

    return getFormatter(options)
  }

  // eslint-disable-next-line class-methods-use-this
  function getFormatNumber() {
    const options = { useGrouping: false }

    return getFormatter(options)
  }

  /**
   * Gets current value in a desired format.
   */
  function getProcessedValue(value) {
    const { minDate, maxDate, maxDetail, returnValue } = props

    const processFunction = (() => {
      switch (returnValue) {
        case 'start':
          return getDetailValueFrom
        case 'end':
          return getDetailValueTo
        case 'range':
          return getDetailValueArray
        default:
          throw new Error('Invalid returnValue.')
      }
    })()

    return processFunction({
      value,
      minDate,
      maxDate,
      maxDetail
    })
  }

  function getPlaceholder() {
    const { format, locale } = props

    if (format) {
      return format
    }

    const year = 2017
    const monthIndex = 11
    const day = 11

    const date = new Date(year, monthIndex, day)

     return getFormatDate()(locale, date)
     .replace(getFormatNumber()(locale, year), 'y')
      .replace(getFormatNumber()(locale, monthIndex + 1), 'M')
      .replace(getFormatNumber()(locale, day), 'd') 
  }

  function getCommonInputProps() {
    const {
      className,
      disabled,
      isCalendarOpen,
      maxDate,
      minDate,
      required
    } = props

    return {
      className,
      disabled,
      maxDate: maxDate || defaultMaxDate,
      minDate: minDate || defaultMinDate,
      onChange: onChange,
      onKeyDown: onKeyDown,
      onKeyUp: onKeyUp,
      // This is only for showing validity when editing
      required: required || isCalendarOpen,
      itemRef: (ref, name) => {
        // Save a reference to each input field
        if (name === "day") {
          dayInput = ref
        } else if (name === "month") {
          monthInput = ref
        } else if (name === "year") {
          yearInput = ref
        }
      }
    }
  }

  function _getValueType() {
    const { maxDetail } = props

    return getValueType(maxDetail) as ValueType
  }

  const onClick = (event) => {
    if (event.target === event.currentTarget) {
      // Wrapper was directly clicked
      const firstInput = event.target.children[1]
      focus(firstInput)
    }
  }

  const onKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
        break
      default:
    }
  }

  const onKeyUp = (event) => {
    const { key, target: input } = event

    const isNumberKey = !isNaN(parseInt(key, 10))

    if (!isNumberKey) {
      return
    }

    const { value } = input
    const max = input.getAttribute('max')

    /**
     * Given 1, the smallest possible number the user could type by adding another digit is 10.
     * 10 would be a valid value given max = 12, so we won't jump to the next input.
     * However, given 2, smallers possible number would be 20, and thus keeping the focus in
     * this field doesn't make sense.
     */
    if (value * 10 > max || value.length >= max.length) {
      const property = 'nextElementSibling'
      const nextInput = findInput(input, property)
      focus(nextInput)
    }
  }

  /**
   * Called when non-native date input is changed.
   */
  const onChange = (event) => {
    const { name, value } = event.target

    Promise.resolve().then().then(() => onChangeExternal)

    setState(
      prev  => 
      ({ ...prev, [name]: value ? parseInt(value, 10) : null })
    )
  }

  /**
   * Called when native date input is changed.
   */
  const onChangeNative = (event) => {
    const { onChange } = props
    const { value } = event.target

    if (!onChange) {
      return
    }

    const processedValue = (() => {
      if (!value) {
        return null
      }

      const [yearString, monthString, dayString] = value.split('-')
      const year = parseInt(yearString, 10)
      const monthIndex = parseInt(monthString, 10) - 1 || 0
      const day = parseInt(dayString, 10) || 1

      const proposedValue = new Date()
      proposedValue.setFullYear(year, monthIndex, day)
      proposedValue.setHours(0, 0, 0, 0)

      return proposedValue
    })()

    onChange(processedValue, false)
  }

  /**
   * Called after internal onChange. Checks input validity. If all fields are valid,
   * calls props.onChange.
   */
  const onChangeExternal = () => {
    const { onChange } = props

    if (!onChange) {
      return
    }

    const formElements = [
      dayInput,
      monthInput,
      yearInput
    ].filter(Boolean)

    const values = {year: '', month: '', day: ''}
    formElements.forEach((formElement) => {
      values[formElement.name] = formElement.value
    })

    if (formElements.every((formElement) => !formElement.value)) {
      onChange(null, false)
    } else if (
      formElements.every(
        (formElement) => formElement.value && formElement.validity.valid
      )
    ) {
      const year = parseInt(values.year, 10)
      const monthIndex = parseInt(values.month, 10) - 1 || 0
      const day = parseInt(values.day || "1", 10)

      const proposedValue = new Date()
      proposedValue.setFullYear(year, monthIndex, day)
      proposedValue.setHours(0, 0, 0, 0)
      const processedValue = getProcessedValue(proposedValue)
      onChange(processedValue, false)
    }
  }

  const renderDay = (currentMatch, index) => {
    const {
      autoFocus,
      dayAriaLabel,
      daygetPlaceholder,
      showLeadingZeros
    } = props
    const { day, month, year } = state

    if (currentMatch && currentMatch.length > 2) {
      throw new Error(`Unsupported token: ${currentMatch}`)
    }

    const showLeadingZerosFromFormat = currentMatch && currentMatch.length === 2

    return (
      <DayInput
        key='day'
        {...getCommonInputProps()}
        ariaLabel={dayAriaLabel}
        autoFocus={index === 0 && autoFocus}
        month={month}
        getPlaceholder={daygetPlaceholder}
        showLeadingZeros={showLeadingZerosFromFormat || showLeadingZeros}
        value={day}
        year={year}
      />
    )
  }

  const renderMonth = (currentMatch, index) => {
    const {
      autoFocus,
      locale,
      monthAriaLabel,
      monthgetPlaceholder,
      showLeadingZeros
    } = props
    const { month, year } = state

    if (currentMatch && currentMatch.length > 4) {
      throw new Error(`Unsupported token: ${currentMatch}`)
    }

    if (currentMatch.length > 2) {
      return (
        <MonthSelect
          key='month'
          {...getCommonInputProps()}
          ariaLabel={monthAriaLabel}
          autoFocus={index === 0 && autoFocus}
          locale={locale}
          getPlaceholder={monthgetPlaceholder}
          short={currentMatch.length === 3}
          value={month}
          year={year}
        />
      )
    }

    const showLeadingZerosFromFormat = currentMatch && currentMatch.length === 2

    return (
      <MonthInput
        key='month'
        {...getCommonInputProps()}
        ariaLabel={monthAriaLabel}
        autoFocus={index === 0 && autoFocus}
        getPlaceholder={monthgetPlaceholder}
        showLeadingZeros={showLeadingZerosFromFormat || showLeadingZeros}
        value={month}
        year={year}
      />
    )
  }

  const renderYear = (currentMatch, index) => {
    const { autoFocus, yearAriaLabel, yeargetPlaceholder } = props
    const { year } = state
    return (
      <YearInput
        key='year'
        {...getCommonInputProps()}
        ariaLabel={yearAriaLabel}
        autoFocus={index === 0 && autoFocus}
        getPlaceholder={yeargetPlaceholder}
        value={year}
        valueType={_getValueType()}
      />
    )
  }

  function renderCustomInputs() {
    const placeholder = getPlaceholder()
    const { format } = props

    const elementFunctions = {
      d: renderDay,
      M: renderMonth,
      y: renderYear
    }

    const allowMultipleInstances = typeof format !== 'undefined'
    return _renderCustomInputs(
      placeholder,
      elementFunctions,
      allowMultipleInstances
    )
  }

  function renderNativeInput() {
    const {
      disabled,
      maxDate,
      minDate,
      name,
      nativeInputAriaLabel,
      required
    } = props
    const { value } = state

    return (
      <NativeInput
        key='date'
        ariaLabel={nativeInputAriaLabel}
        disabled={disabled}
        maxDate={maxDate || defaultMaxDate}
        minDate={minDate || defaultMinDate}
        name={name}
        onChange={onChangeNative}
        required={required}
        value={value}
        valueType={_getValueType()}
      />
    )
  }


    const { className } = props

    return (
      <ThemeProvider theme={theme}>
        <div className={className} onClick={onClick}>
          {renderNativeInput()}
          {renderCustomInputs()}
        </div>
      </ThemeProvider>
    )
  
}

DateInput.defaultProps = {
  maxDetail: 'month',
  name: 'date',
  returnValue: 'start'
}

const isValue = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.instanceOf(Date)
])

DateInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string.isRequired,
  dayAriaLabel: PropTypes.string,
  daygetPlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  isCalendarOpen: PropTypes.bool,
  locale: PropTypes.string,
  maxDate: isMaxDate,
  maxDetail: PropTypes.oneOf(allViews),
  minDate: isMinDate,
  monthAriaLabel: PropTypes.string,
  monthgetPlaceholder: PropTypes.string,
  name: PropTypes.string,
  nativeInputAriaLabel: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  returnValue: PropTypes.oneOf(['start', 'end', 'range']),
  showLeadingZeros: PropTypes.bool,
  value: PropTypes.oneOfType([isValue, PropTypes.arrayOf(isValue)]),
  yearAriaLabel: PropTypes.string,
  yeargetPlaceholder: PropTypes.string
}
