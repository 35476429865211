import styled from 'styled-components'
import { colorStyle } from 'styled-system'



export const Component = styled.ol<{
  bold?: boolean
  light?: boolean
  circle?: boolean
  uppercase?: boolean
  colors?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "none"
}>`
  ${colorStyle};
  font-weight: ${(props) =>
    props.bold ? 'bold' : props.light ? 'light' : null};
  border-radius: ${(props) => (props.circle ? '50%' : '0')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'capitalize')};
`
