import styled from "styled-components";

export const Component = styled.div<{
  type: string
  href: string
  header: boolean
  divider: boolean
  disabled: boolean
}>`
  color: #000 !important;
`;
