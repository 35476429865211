import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import mergeClassNames from 'merge-class-names'
import Calendar from 'react-calendar'
import Fit from 'react-fit'
import DateInput from './DateInput'
import { isMaxDate, isMinDate } from './shared/propTypes'
import { Component, CalenderFit } from './DatePicker.style'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'

const baseClassName = 'react-date-picker'
const allViews = ['century', 'decade', 'year', 'month']

interface Props {
  autoFocus?: boolean,
  calendarAriaLabel?: string,
  calendarClassName?:string | string[],
  calendarIcon?: React.ReactNode,
  className?: string | string[],
  clearAriaLabel?: string,
  clearIcon?: React.ReactNode,
  closeCalendar?: boolean,
  color?: string,
  dayAriaLabel?: string,
  dayPlaceholder?: string,
  disableCalendar?: boolean,
  disabled?: boolean,
  format?: string,
  isOpen?: boolean,
  locale?: string,
  maxDate?: Date,
  maxDetail?: 'century'| 'decade'| 'year'| 'month',
  minDate?: Date,
  monthAriaLabel?: string,
  monthPlaceholder?: string,
  name?: string,
  nativeInputAriaLabel?: string,
  onCalendarClose?: Function,
  onCalendarOpen?: Function,
  onChange?: Function,
  onFocus?: Function,
  required?: boolean,
  returnValue?: 'start'| 'end'| 'range',
  showLeadingZeros?: boolean,
  value?: any | any[],
  yearAriaLabel?: string,
  yearPlaceholder?: string
}

const DatePicker = ({
  className,
  onCalendarClose,
  onCalendarOpen,
  disabled,
  onFocus,
  onChange,
  autoFocus,
  calendarAriaLabel,
  calendarIcon,
  clearAriaLabel,
  clearIcon,
  color,
  dayAriaLabel,
  dayPlaceholder,
  disableCalendar,
  format,
  locale,
  maxDate,
  maxDetail,
  minDate,
  monthAriaLabel,
  monthPlaceholder,
  name,
  nativeInputAriaLabel,
  required,
  returnValue,
  showLeadingZeros,
  value,
  yearAriaLabel,
  yearPlaceholder,
  calendarClassName,
  ...props
}: Props) => {
  const calenderRef = useRef(null)
  const wrapperRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const callback = isOpen ? onCalendarOpen : onCalendarClose
    if (callback) callback()
    addSvgEvents()
  }, [isOpen])

  function addSvgEvents() {
    const prev2 = calenderRef.current.querySelector(
      '.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button'
    )
    prev2.innerHTML = `<i class="fa fa-angle-double-left" style="font-size: 25px" aria-hidden="true"></i>`
    const prev = calenderRef.current.querySelector(
      '.react-calendar__navigation__arrow.react-calendar__navigation__prev-button'
    )
    prev.innerHTML = `<i class="fa fa-chevron-left" aria-hidden="true"></i>`
    const next = calenderRef.current.querySelector(
      '.react-calendar__navigation__arrow.react-calendar__navigation__next-button'
    )
    next.innerHTML = `<i class="fa fa-chevron-right" aria-hidden="true"></i>`
    const next2 = calenderRef.current.querySelector(
      '.react-calendar__navigation__arrow.react-calendar__navigation__next2-button'
    )
    next2.innerHTML = `<i class="fa fa-angle-double-right" style="font-size: 25px" aria-hidden="true"></i>`
  }

  const handleChange = (value, closeCalendar) => {
    if (closeCalendar) {
      setIsOpen(false)
    }
    if (onChange) {
      onChange(value)
    }
  }

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event)
    }
    setIsOpen(true)
  }

  const stopPropagation = (event) => event.stopPropagation()

  const clear = () => handleChange(null, null)

  function renderInputs() {
    const [valueFrom] = [].concat(value)

    const ariaLabelProps = {
      dayAriaLabel,
      monthAriaLabel,
      nativeInputAriaLabel,
      yearAriaLabel
    }

    const placeholderProps = {
      dayPlaceholder,
      monthPlaceholder,
      yearPlaceholder
    }
    return (
      <ThemeProvider theme={theme}>
        <Component color={color}>
          <div className={`${baseClassName}__wrapper`}>
            <DateInput
              {...ariaLabelProps}
              {...placeholderProps}
              autoFocus={autoFocus}
              className={`${baseClassName}__inputGroup`}
              disabled={disabled}
              format={format}
              isCalendarOpen={isOpen}
              locale={locale}
              maxDate={maxDate}
              maxDetail={maxDetail}
              minDate={minDate}
              name={name}
              onChange={handleChange}
              required={required}
              returnValue={returnValue}
              showLeadingZeros={showLeadingZeros}
              value={valueFrom}
            />
            {clearIcon !== null && (
              <button
                aria-label={clearAriaLabel}
                className={`${baseClassName}__clear-button ${baseClassName}__button`}
                disabled={disabled}
                onClick={clear}
                onFocus={stopPropagation}
                type='button'
              >
                {clearIcon}
              </button>
            )}
            {calendarIcon !== null && !disableCalendar && (
              <button
                aria-label={calendarAriaLabel}
                className={`${baseClassName}__calendar-button ${baseClassName}__button`}
                disabled={disabled}
                onClick={() => setIsOpen(!isOpen)}
                onFocus={stopPropagation}
                type='button'
              >
                {calendarIcon}
              </button>
            )}
          </div>
        </Component>
      </ThemeProvider>
    )
  }

  function renderCalendar() {
    if (isOpen === null || disableCalendar) {
      return null
    }

    const className = `${baseClassName}__calendar`

    return (
      <CalenderFit color={color} ref={calenderRef} isOpen={isOpen}>
        <Fit>
          <div
            style={{ maxHeight: '213px' }}
            className={mergeClassNames(
              className,
              `${className}--${isOpen ? 'open' : 'closed'}`
            )}
          >
            <Calendar
              className={calendarClassName}
              onChange={handleChange}
              value={value || null}
              {...props}
            />
          </div>
        </Fit>
      </CalenderFit>
    )
  }

  return (
    <div
      className={mergeClassNames(
        baseClassName,
        `${baseClassName}--${isOpen ? 'open' : 'closed'}`,
        `${baseClassName}--${disabled ? 'disabled' : 'enabled'}`,
        className
      )}
      {...props}
      onFocus={handleFocus}
      ref={wrapperRef}
    >
      {renderInputs()}
      {renderCalendar()}
    </div>
  )
}

const iconProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  width: 19,
  height: 19,
  viewBox: '0 0 19 19',
  stroke: 'black',
  strokeWidth: 2,
  version: '1.1'
}

const CalendarIcon = (
  <svg
    {...iconProps}
    className={`${baseClassName}__calendar-button__icon ${baseClassName}__button__icon`}
    viewBox='0 0 24 24'
    enableBackground='new 0 0 24 24'
  >
    <path d='M2,19c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-8H2V19z M19,4h-2V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H9V3c0-0.6-0.4-1-1-1S7,2.4,7,3v1H5C3.3,4,2,5.3,2,7v2h20V7C22,5.3,20.7,4,19,4z' />
  </svg>
)
const ClearIcon = (
  <svg
    {...iconProps}
    className={`${baseClassName}__clear-button__icon ${baseClassName}__button__icon`}
  >
    <line x1='4' x2='15' y1='4' y2='15' />
    <line x1='15' x2='4' y1='4' y2='15' />
  </svg>
)

DatePicker.defaultProps = {
  calendarIcon: CalendarIcon,
  clearIcon: ClearIcon,
  closeCalendar: true,
  color: 'dark',
  isOpen: null,
  returnValue: 'start',
  className: ""
}

const isValue = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.instanceOf(Date)
])

DatePicker.propTypes = {
  autoFocus: PropTypes.bool,
  calendarAriaLabel: PropTypes.string,
  calendarClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  calendarIcon: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  clearAriaLabel: PropTypes.string,
  clearIcon: PropTypes.node,
  closeCalendar: PropTypes.bool,
  color: PropTypes.string,
  dayAriaLabel: PropTypes.string,
  dayPlaceholder: PropTypes.string,
  disableCalendar: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  isOpen: PropTypes.bool,
  locale: PropTypes.string,
  maxDate: isMaxDate,
  maxDetail: PropTypes.oneOf(allViews),
  minDate: isMinDate,
  monthAriaLabel: PropTypes.string,
  monthPlaceholder: PropTypes.string,
  name: PropTypes.string,
  nativeInputAriaLabel: PropTypes.string,
  onCalendarClose: PropTypes.func,
  onCalendarOpen: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  returnValue: PropTypes.oneOf(['start', 'end', 'range']),
  showLeadingZeros: PropTypes.bool,
  value: PropTypes.oneOfType([isValue, PropTypes.arrayOf(isValue)]),
  yearAriaLabel: PropTypes.string,
  yearPlaceholder: PropTypes.string
}

export default DatePicker

export { DatePicker as CDBDatePicker }
