import styled from "styled-components";
import { colorStyle } from "styled-system";

export const Component = styled.div<{
  dropleft?: boolean,
  dropright?: boolean,
  dropup?: boolean,
  isOpen?: boolean,
}>`
  ${colorStyle}
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
`;
