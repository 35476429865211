import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Component } from './CardText.style';
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";

interface Props {
    children?: React.ReactNode,
    className?: string,
    muted?: boolean,
    small?: boolean,
    tag?: Function| string
}

const CardText = (props: Props) => {
    const { children: textNode, className, muted, small, tag, ...attributes } = props;

    const classes = classNames('card-text', muted && 'text-muted', className);

    const children = small ? <small>{textNode}</small> : textNode;

    return (
        <ThemeProvider theme={theme}>
            <Component data-test='card-text' as={(tag as unknown) as undefined} {...attributes} className={classes}>
                {children}
            </Component>
        </ThemeProvider>
    );
};

CardText.propTypes = {
    className: PropTypes.string,
    muted: PropTypes.bool,
    small: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

CardText.defaultProps = {
    tag: 'p'
};

export default CardText;
export { CardText as CDBCardText };