import  React,{ useState } from "react";
import PropTypes from "prop-types";
import CDBInput from "../Input";
import { StyledComponent } from "./Autocomplete.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props {
  label?: string;
  getValue?: Function;
  suggestions?: any;
}

const Autocomplete = (props: Props) => {
  const { label, getValue, suggestions, ...attributes } = props;

  const [state, setState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: "",
  });

  const onChange = (e) => {
    const userInput = e.target.value;
    if (suggestions) {
      const filteredSuggestions = suggestions.filter(
        (suggestion: any) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.target.value,
      });
      console.log(state)
      getValue && getValue(e.target.value);
    } else {
      return null;
    }
  };
  const onClick = (e) => {
    setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    })

    getValue && getValue(e.currentTarget.innerText);
  };
  const onKeyDown = (e) => {
    const { filteredSuggestions, activeSuggestion } = state;

    if (e.keyCode === 13) {
      setState((prevState) => ({
        ...prevState,
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion!],
      }));
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        activeSuggestion: activeSuggestion! - 1,
      }));
    } else if (e.keyCode === 40) {
      if (activeSuggestion! - 1 === filteredSuggestions.length) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        activeSuggestion: activeSuggestion! + 1,
      }));
    }
  };

  let suggestionsListComponent;

  if (state.showSuggestions && state.userInput) {
    if (state.filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {state.filteredSuggestions.map((suggestion, index) => {
            return (
              <li key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = null;
    }
  }
  const {userInput} = state

  return (
    <ThemeProvider theme={theme}>
      <StyledComponent>
        <CDBInput
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          labelClassName={label}
          {...attributes}
        />
        {suggestionsListComponent}
      </StyledComponent>
    </ThemeProvider>
  );
};
Autocomplete.propTypes = {
  suggestion: PropTypes.array,
  getValue: PropTypes.func,
  label: PropTypes.string,
};
Autocomplete.defaultProps = {
  suggestion: [],
};
export default Autocomplete;
export { Autocomplete as CDBAutocomplete };
