const colors = {
  white: '#FFFFFF',
  light: '#EEEEEE',

  // primary
  primary: '#3b82f6',
  primary50: '#eff6ff',
  primary100: '#dbeafe',
  primary200: '#bfdbfe',
  primary300: '#93c5fd',
  primary400: '#60a5fa',
  primary500: '#3b82f6',
  primary600: '#2563eb',
  primary700: '#1d4ed8',
  primary800: '#1e40af',
  primary900: '#1e3a8a',

  // secondary
  secondary: '#6366f1',
  secondary50: '#eef2ff',
  secondary100: '#e0e7ff',
  secondary200: '#c7d2fe',
  secondary300: '#a5b4fc',
  secondary400: '#818cf8',
  secondary500: '#6366f1',
  secondary600: '#4f46e5',
  secondary700: '#4338ca',
  secondary800: '#3730a3',
  secondary900: '#312e81',

  // danger
  danger: '#ef4444',
  danger50: '#fef2f2',
  danger100: '#fee2e2',
  danger200: '#fecaca',
  danger300: '#fca5a5',
  danger400: '#f87171',
  danger500: '#ef4444',
  danger600: '#dc2626',
  danger700: '#b91c1c',
  danger800: '#991b1b',
  danger900: '#7f1d1d',

  // success
  success: '#22c55e',
  success50: '#f0fdf4',
  success100: '#dcfce7',
  success200: '#bbf7d0',
  success300: '#86efac',
  success400: '#4ade80',
  success500: '#22c55e',
  success600: '#16a34a',
  success700: '#15803d',
  success800: '#166534',
  success900: '#14532d',

  // warning
  warning: '#eab308',
  warning50: '#fefce8',
  warning100: '#fef9c3',
  warning200: '#fef08a',
  warning300: '#fde047',
  warning400: '#facc15',
  warning500: '#eab308',
  warning600: '#ca8a04',
  warning700: '#a16207',
  warning800: '#854d0e',
  warning900: '#713f12',

  // brown
  brown: '#bfa094',
  brown50: '#fdf8f6',
  brown100: '#f2e8e5',
  brown200: '#eaddd7',
  brown300: '#e0cec7',
  brown400: '#d2bab0',
  brown500: '#bfa094',
  brown600: '#a18072',
  brown700: '#977669',
  brown800: '#846358',
  brown900: '#43302b',

  // dark
  dark: '#111827',
  dark50: '#f9fafb',
  dark100: '#f3f4f6',
  dark200: '#e5e7eb',
  dark300: '#d1d5db',
  dark400: '#9ca3af',
  dark500: '#6b7280',
  dark600: '#4b5563',
  dark700: '#374151',
  dark800: '#1f2937',
  dark900: '#111827',

  info: '#17A2B8',
  info100: '#17A2B8BD',

  primaryHover: '#276EF1BD',
  secondaryHover: '#7356BFBD',
  successHover: '#05944FBD',
  warningHover: '#FFE975BD',
  dangerHover: '#E11900BD',
  whiteHover: '#FFFFFFD5',
  darkHover: '#000000BD',
  infoHover: '#17A2B8BD',
  lightHover: '#EEEEEEBD'
}

export default colors
