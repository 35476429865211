import styled from "styled-components";

export const Component = styled.div`
.tab-content{
  margin-top: 30px;
}
  .tab-content .tab-pane {
    display: none;
    transition: opacity 300ms linear;
  }

  .tab-content .tab-pane.active {
    display: block;
  }
`;
