import styled, {css} from "styled-components";
import { variant } from "styled-system";

export const Component = styled.img`

	border-radius:50%;
	overflow:hidden;
	position:relative;

	${variant({
		prop: "size",
		variants: {
		  lg: {
			width:"4.5rem",
			height:"4.5rem",
		  },
		  md: {
			width:"3rem",
			height:"3rem",
		  },
		  sm: {
			width:"2.5rem",
			height:"2.5rem",
		  },
		  xs: {
			width:"2rem",
			height:"2rem",
		  },
		},
	})};

	${(props) =>
    	props.width
      ? css`
          width:${props.width};
          height:${props.height? props.height : props.width};
        `
      : css``};
`;