import styled, { css } from "styled-components";
import { variant } from "styled-system";

export const Component = styled.div<{
  position: string
}>`
  flex-basis: none !important;
  border: none;
  margin: 0 10px;
  position: relative;
  transition: 1s;
  .toast-message {
    transition: 1s;
    padding: 10px;
  }
  .toast-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    position: relative;
    width: 100%;
    gap: 6px;
    .close-icon {
      position: absolute;
      right: 10px;
      top: 5px;
      background: none;
      border: none;
    }
    .body {
      display: flex;
      flex-direction: column;
      .small-text {
        color: #e5e5e5;
      }
    }
    .toggle {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      span {
        width: 50px;
        height: 4px;
        background: #e5e5e5;
        border-radius: 2px;
        margin-bottom: 3px;
        cursor: pointer;
        pointer-events: auto;
      }
    }

    .icon-box {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: ${(props) =>
    props.color === "primary"
      ? "#3e98c7"
      : props.color === "secondary"
        ? "#7356BF"
        : props.color === "danger"
          ? "#E11900"
          : props.color === "warning"
            ? "#66512C"
            : props.color === "info"
              ? "#17A2B8"
              : props.color === "success"
                ? "#05944F"
                : "#000000"};
      & > * {
        color: white;
      }

    }
  }

  ${(props) =>
    props.position
      ? css`
          position: absolute;
          z-ndex: 9999;
          min-width: 350px;
        `
      : css``};

  ${variant({
        prop: "position",
        variants: {
          topLeft: {
            top: "10px",
            left: "10px",
          },
          topRight: {
            top: "10px",
            right: "10px",
          },
          bottomLeft: {
            bottom: "10px",
            left: "10px",
          },
          bottomRight: {
            bottom: "10px",
            right: "10px",
          },
        }
      })};
  
`;
