import styled, { css } from 'styled-components'
import colors from '../../theme/colors'

interface Props {
  color: string
}

export const Component = styled.div<Props>`
  .react-date-picker {
    display: inline-flex;
    position: relative;
  }
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 350px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 5px 10px;
    background: #eeeeee;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
  }
  .react-date-picker__wrapper:hover,
  .react-date-picker__wrapper:focus {
    ${({ color }) =>
    color &&
    css`
        border: 3px solid ${colors[color]};
        background: ${colors[`${color}100`]};
        * {
          font-weight: bold !important;
          color: ${colors[color]} !important;
        }
        .react-date-picker__button {
          svg {
            ${({ color }: any) =>
        color &&
        css`
                fill: ${colors[color]} !important;
                fill-width: 3px;
                stroke: ${colors[color]} !important;
                stroke-width: 3px;
              `}
          }
        }
      `}
  }
  .react-date-picker__inputGroup {
    flex-grow: 1;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    padding-right: 20px;
  }
  .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    margin: 0 2px;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
  }
  .react-date-picker__inputGroup__input:hover,
  .react-date-picker__inputGroup__input:focus {
    outline: none;
    border: none !important;
    background: #f0f0f0;
    ${({ color }) =>
    color &&
    css`
        background: ${colors[`${color}200`]} !important;
        color: ${colors[`${color}700`]} !important;
        font-weight: 900;
      `}
  }
  .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: rgba(0, 0, 0, 0.1);
  }
  .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    svg {
      fill: #cccccc;
      stroke: #cccccc;
    }
  }
  .react-date-picker__button:hover,
  .react-date-picker__button:focus {
    border: none;
    outline: none;
  }
  .react-date-picker__button:enabled {
    cursor: pointer;
  }
  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
    outline: none;
    border: none !important;
  }
  .react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-date-picker__button svg {
    display: inherit;
  }
  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    border-radius: 8px;
  }
  .react-date-picker__calendar--closed {
    display: none;
  }
  .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }
`

export const CalenderFit = styled.div<any>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none !important;
    `}
  .react-calendar {
    width: 550px !important;
    max-width: 100%;
    border: none !important;
    line-height: 1.125em;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    padding: 20px 10px;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    ${({ color }) =>
    color &&
    css`
        background: ${colors[`${color}600`]};
      `}
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 20px;
    font-weight: bold;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation_label {
    background: #000 !important;
  }
  .react-calendar__navigation__label__labelText,
  .react-calendar__navigation__label__labelText--from,
  .react-calendar__navigation__arrow {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 20px;
    font-family: Montserrat, sans-serif;
    ${({ color }) =>
    color === 'warning' || color === 'light'
      ? css`
            color: #333 !important;
          `
      : css`
            color: #eee !important;
          `};
  }
  .react-calendar__month-view__days,
  .react-calendar__year-view__months {
    padding: 10px;
  }
  .react-calendar__month-view__days .react-calendar__tile {
    height: 48.5px;
    border-radius: 50%;
  }
  .react-calendar__tile--now {
    color: #fff !important;
    ${({ color }) =>
    color &&
    css`
        background: ${colors[color]} !important;
      `}
  }
  .react-calendar__tile--hasActive {
    color: #fff !important;
    ${({ color }) =>
    color &&
    css`
        background: ${colors[color]};
      `}
  }
  .react-calendar__tile--active {
    background: #f6f6f6 !important;
    color: #000 !important;
    border: 2px solid #e2e2e2 !important;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    padding: 10px 0;
    margin-bottom: 15px;
    ${({ color }) =>
    color &&
    css`
        background: ${colors[color]} !important;
      `}
    color: #fff;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    text-transform: capitalize;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    text-transform: capitalize;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
    text-transform: capitalize;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(1em) calc(0.6666666666666666em);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    border-radius: 4px !important;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    ${({ color }) =>
    color &&
    css`
        background: ${colors[color]};
      `}
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    ${({ color }) =>
    color &&
    css`
        background: ${colors[color]};
      `}
  }
  .react-calendar__tile--active {
    ${({ color }) =>
    color &&
    css`
        background: ${colors[color]};
      `}
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`
