import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'


interface Props {
  fill?: string, colorfill?: string, name?: string, value?: any,
}

const Radio = (props: Props) => {
  const { fill, colorfill, name, value, ...attributes } = props
  let [state, setState] = useState({
    chosen: false,
    hovered: false,
    moused: false
  })

  const handleClick = (e) => {
    e.preventDefault()

    return setState((state) => ({
      ...state,
      moused: false,
      chosen: !state.chosen
    }))
  }
  const handleMouseDown = (e) => {
    e.preventDefault()

    return setState((state) => ({
      ...state,
      moused: true
    }))
  }
  const handleMouseEnter = (e) => {
    e.preventDefault()

    return setState((state) => ({
      ...state,
      hovered: true
    }))
  }
  const handleMouseLeave = (e) => {
    e.preventDefault()

    return setState((state) => ({
      ...state,
      hovered: false
    }))
  }

  const radioGroupStyle : React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
  const radioBtnContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '1rem',
    padding: '1rem'
  }
  const radioBtnInputStyle: React.CSSProperties = {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0
  }
  const radioBtnUncheckedStyle = {
    border: `.2rem solid ${fill}`
  }
  const radioBtnCheckedStyle = {
    backgroundColor: fill,
    border: `.2rem solid ${colorfill}`,
    boxShadow: `0 0 0 .2rem ${fill}`
  }
  const radioBtnHoveredStyle = {
    boxShadow: `0 0 0 .4rem ${fill}75`,
    border: `.2rem solid ${fill}`
  }
  const radioBtnMousedStyle = {
    border: `.2rem solid ${fill}75`
  }
  const radioBtnStyle = {
    display: 'inline-block',
    height: '.9rem',
    width: '.9rem',
    borderRadius: '50%',
    marginRight: '1rem',
    transition: '.15s ease-in-out'
  }

  return (
    <ThemeProvider theme={theme}>
      <div id='radio-group' style={radioGroupStyle} {...attributes}>
        <label
          onClick={(e) => handleClick(e)}
          onMouseEnter={(e) => handleMouseEnter(e)}
          onMouseLeave={(e) => handleMouseLeave(e)}
          onMouseDown={(e) => handleMouseDown(e)}
          style={radioBtnContainerStyle}
        >
          <input
            type='radio'
            value={value}
            name={name}
            checked={state.chosen}
            onChange={handleClick}
            style={radioBtnInputStyle}
          />
          <div
            style={{
              ...radioBtnStyle,
              ...(state.chosen
                ? radioBtnCheckedStyle
                : state.moused
                  ? radioBtnMousedStyle
                  : state.hovered
                    ? radioBtnHoveredStyle
                    : radioBtnUncheckedStyle)
            }}
          />
          {value}
        </label>
      </div>
    </ThemeProvider>
  )
}

Radio.defaultProps = {
  fill: '#455ff5'
}

Radio.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string
}

export default Radio

export { Radio as CDBRadio }
