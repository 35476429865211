import styled from "styled-components";
import { InnerMenuItem } from "../SidebarMenuItem/SidebarMenuItem.style";

export const InnerMenu = styled.div<{
  textColor: string
  backgroundColor: string
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 2px 8px 15px;
  height: 45px;
  cursor: pointer;
  outline: none;
  .side-icon {
    margin-right: 15px;
    width: 30px;
  }

  .item-content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.toggled {
    .item-content {
      display: block !important;
    }
  }
  &.active {
    background: ${(props) => props.textColor};
    color: ${(props) => props.backgroundColor};
    .pro-arrow-wrapper {
      .pro-arrow {
        border-color: ${(props) => props.backgroundColor};
      }
    }
  }

  &:before {
    background: ${(props) => props.textColor};
  }

  .pro-arrow-wrapper {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);

    .pro-arrow {
      display: inline-block;
      border-style: solid;

      border-width: 0 2px 2px 0;
      padding: 2.5px;
      vertical-align: middle;
      transition: transform 0.3s;
      transform: rotate(-45deg);
    }
  }
  .suffix-wrapper {
    right: 10%;
    position: absolute;
    opacity: 1;
  }
  &.toggled {
    pointer-events: none;
    padding-left: 9px;
    .pro-arrow-wrapper {
      display: none;
    }
    .suffix-wrapper {
      opacity: 0;
    }
  }
`;

export const PopperElement = styled.div<{
  backgroundColor: string
}>`
  height: auto;
  position: fixed;
  visibility: hidden;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  padding-left: 3px;
  &.has-arrow {
    padding-left: 10px;
  }
  .pro-inner-item {
    &:before {
      content: "";
      display: inline-block;
      width: 8px;
      min-width: 8px;
      height: 8px;
      border: 1px solid ${(props) => props.backgroundColor};
      border-radius: 50%;
      margin-right: 4px;
      position: relative;
      margin-left: 0px;
    }
  }
`;

export const PopperInner = styled.div<{
  backgroundColor: string
}>`
  ${PopperElement};
  max-height: 100vh;
  overflow-y: auto;
  background-color: ${(props) => props.backgroundColor};
  padding-left: 20px;
  border-radius: 4px;
  ul {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px;
    li {
      margin: 0px 5px;
      div {
        height: auto;
        pointer-events: auto !important;
      }
    }
  }
`;

export const SubMenuContainerLi = styled.li`
  list-style: none;
  margin: 10px 15px;

  ${InnerMenuItem} {
    .item-content {
      display: block !important;
    }
  }
  .react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }

  .react-slidedown.transitioning {
    overflow-y: hidden;
  }

  .react-slidedown.closed {
    display: none;
  }
  .popper-inner {
    .pro-item-content {
      display: block !important;
    }
  }
  &.toggled {
    .pro-item-content {
      display: none;
    }
  }
  .pro-item-content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.open {
    .pro-inner-item {
      > .pro-arrow-wrapper {
        .pro-arrow {
          transform: rotate(45deg);
        }
      }
    }
  }

  &.toggled {
    position: relative;
    .pro-inner-list-item {
      z-index: 111;
    }

    &:hover {
      .pro-inner-list-item {
        visibility: visible;
      }
      .pro-icon-wrapper {
        .pro-icon {
          animation: swing ease-in-out 0.5s 1 alternate;
        }
      }
    }
    .pro-inner-list-item {
      .pro-sub-menu-item,
      .pro-inner-item {
        padding: 8px 30px 8px 5px;
      }
    }
  }
`;
