import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component, Options, Div, Option } from "./Select2.style";
import CDBIcon from "../Icon";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props {
  tag?: any,
  color?: string,
  disabled?: boolean,
  iconSize?: string,
  iconBrand?: boolean,
  iconLight?: boolean,
  iconRegular?: boolean,
  iconClass?: string,
  options?: any[],
  hoverColor?: boolean,
  selected?: string,
  className?: string,
  optionClassName?: string,
}

const Select2 = (props: Props) => {
  const {
    tag,
    color,
    options,
    disabled,
    iconSize,
    iconBrand,
    iconClass,
    iconLight,
    iconRegular,
    selected,
    className,
    hoverColor,
    optionClassName,
    ...attributes
  } = props;

  const [show, setShow] = useState(false);
  const [active, setActive] = useState([selected]);
  const wrapperRef = useRef(null);

  const selectClasses = classNames((show && "shadow"), { disabled }, className);
  const optionClasses = classNames((show && "display"), optionClassName);
  const divClass = classNames((show && "active"));

  const display = (e) => {
    e.stopPropagation();
    setShow(!show);
  }

  const setSelected = (text, icon) => {
    setActive([text, icon]);
    setShow(!show);
  }

  const useOutsideClose = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  let selectComponent = (
    <ThemeProvider theme={theme}>
      <Component
        data-test="select"
        colors={color}
        className={selectClasses}
        ref={wrapperRef}
        onClick={useOutsideClose(wrapperRef) as unknown as React.MouseEventHandler<HTMLDivElement>}
        as={(tag as unknown) as undefined} {...attributes}
      >
        <Div className={divClass} onClick={display}>
          <Option onClick={display}>
            {active[1] &&
              <CDBIcon
                size={iconSize}
                brand={iconBrand}
                light={iconLight}
                regular={iconRegular}
                className={iconClass}
                icon={active[1]}
              />}
            {active[0]}
          </Option>
          <CDBIcon fas icon="angle-down" onClick={display} className="icon" />
        </Div>
        <Options colors={hoverColor && color} className={optionClasses}>
          {options &&
            options.map((optionType, index) => {
              const { icon, text } = optionType;
              return (
                <div key={index} onClick={() => setSelected(text, icon)}>
                  {icon &&
                    <CDBIcon
                      size={iconSize}
                      brand={iconBrand}
                      light={iconLight}
                      regular={iconRegular}
                      className={iconClass}
                      icon={icon}
                    />}
                  <Option>{text}</Option>
                </div>
              );
            })
          }
        </Options>
      </Component>
    </ThemeProvider>
  );

  return selectComponent;
};

Select2.propTypes = {
  tag: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  iconSize: PropTypes.string,
  iconBrand: PropTypes.bool,
  iconLight: PropTypes.bool,
  iconRegular: PropTypes.bool,
  iconClass: PropTypes.string,
  options: PropTypes.array,
  hoverColor: PropTypes.bool,
  selected: PropTypes.string,
  className: PropTypes.string,
  optionClassName: PropTypes.string,
};

Select2.defaultProps = {
  tag: "div",
};

export default Select2;
export { Select2 as CDBSelect2 };
