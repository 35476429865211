import styled from "styled-components";

export const Component = styled.div`
	width:18px;
	height:18px;
	cursor:pointer;
	display-inline-block;
	border:1px solid #888;
	position:relative;

	div {
		position:absolute;
	    top: 0px;
	    left: 10px;
		transition:all 0.1s ease-in;
	}

	&.active {
	    border-color:#000;
		div {
		    top: 1px;
		    left: 5px;
		    width: 6px;
			height:12px;
			border:solid #000;
			border-width: 0px 2px 2px 0px;
			transform:rotate(45deg);
		}
	}

	&.disabled {
		pointer-events:none;
		background-color:#eee;
		border-color:#eee;
	}
`;
