import styled from "styled-components";
import { variant } from "styled-system";

export const Component = styled.div<{
	colors: string
}>`
	width:100%;
	transition: all 0.3s ease-in-out;
	${variant({
	prop: "colors",
	scale: "lightStyle",
})}

	i {
		margin-right:5px;
	}

	&.disabled {
		pointer-events:none;
		opacity:0.6;
	}
`;

export const Div = styled.div<any>`
	display:flex;
	padding:10px;
	cursor:pointer;
	transition: inherit;
	border-width:1px;
	border-style:solid;
	box-sizing:border-box;
	align-items:center;
	justify-content:space-between;

	.icon {
    	margin:0;
    	opacity: 0.5;
    	transition: inherit;
	}

	&.active{
		.icon{
			opacity:1;
			transform: rotate(-180deg);
		}
	}
`;

export const Options = styled.div<any>`
	color:#000;
	background:#fff;
	max-height:0px;
	overflow-y:scroll;
	transition: inherit;

	&.display {
		max-height:150px;
	}

	::-webkit-scrollbar {
		width:3px;
		background-color:transparent;
	}

	::-webkit-scrollbar-thumb {
		border-radius:5px;
		background-color: #ccc;
	}

	div {
		display:flex;
		align-items:center;
		padding:10px;
		cursor:pointer;
		transition: all 0.1s ease-in-out;

		:hover{
			background-color:#ddd;
			${variant({
	prop: "colors",
	scale: "lightStyle",
})}
		}
	}
`;

export const Option = styled.span<any>`
	font-size:0.9em;
`;
