import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./Nav.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props {
  children?: React.ReactNode,
  classicTabs?: boolean,
  className?: string,
  color?: string,
  header?: boolean,
  pills?: boolean,
  tabs?: boolean,
  tag?: Function | string,
}


const Nav = (props: Props) => {
  const {
    children,
    className,
    tag,
    tabs,
    color,
    classicTabs,
    pills,
    header,
    ...attributes
  } = props;

  const classes = classNames(
    "nav",
    tabs && "md-tabs",
    pills && "md-pills",
    header && "nav-pills card-header-pills",
    color && !tabs && !classicTabs && !pills ? color : false,
    pills && color ? `pills-${color}` : false,
    (tabs || classicTabs) && color ? `tabs-${color}` : false,
    className
  );

  return (
    <ThemeProvider theme={theme}>
      <Component as={(tag as unknown) as undefined} data-test="nav" {...attributes} className={classes}>
        {children}
      </Component>
    </ThemeProvider>
  );
};

Nav.propTypes = {
  children: PropTypes.node,
  classicTabs: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  header: PropTypes.bool,
  pills: PropTypes.bool,
  tabs: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Nav.defaultProps = {
  tag: "ul",
  classicTabs: false,
  pills: false,
  tabs: false,
  header: false,
};

export default Nav;
export { Nav as CDBNav };