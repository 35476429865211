import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Component } from './CardTitle.style';
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";

interface Props {
    className?: string,
    sub?: boolean,
    tag?: Function | string
}

const CardTitle = (props: Props) => {
    const { className, sub, tag, ...attributes } = props;

    const classes = classNames(sub ? 'card-subtitle' : 'card-title', className);

    return (
        <ThemeProvider theme={theme}>
            <Component data-test='card-title' as={(tag as unknown) as undefined} {...attributes} className={classes} />
        </ThemeProvider>
    );
};

CardTitle.propTypes = {
    className: PropTypes.string,
    sub: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

CardTitle.defaultProps = {
    tag: 'h4',
    sub: false
};

export default CardTitle;
export { CardTitle as CDBCardTitle };