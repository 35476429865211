import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./PanelText.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";

interface Props{
  className?: string,
  small?: boolean,
  muted?: boolean,
  tag?: any,
  children?: React.ReactNode
}

const PanelText = (props: Props) => {
  const {
    className,
    tag,
    small,
    muted,
    children,
    ...attributes
  } = props;

  const panelTextClasses = classNames(className);

  let panelTextComponent = (
    <ThemeProvider theme={theme}>
      <Component {...attributes} as={tag} small={small} muted={muted} className={panelTextClasses}>
        {children}
      </Component>
    </ThemeProvider>
  );

  return panelTextComponent;
};

PanelText.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  muted: PropTypes.bool,
};

PanelText.defaultProps = {
  tag: "p",
};

export default PanelText;
export { PanelText as CDBPanelText };
