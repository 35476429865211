import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./Pane.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";


interface Props {
  className?: string,
  tag?: any;
}

const Pane = (props: Props) => {
  const {
    className,
    tag,
    ...attributes
  } = props;

  const paneClasses = classNames(className);

  let paneComponent = (
    <ThemeProvider theme={theme}>
      <Component as={tag} {...attributes} className={paneClasses}></Component>
    </ThemeProvider>
  );

  return paneComponent;
};

Pane.propTypes = {
  className: PropTypes.string,
};

Pane.defaultProps = {
  tag: "div",
};

export default Pane;
export { Pane as CDBPane };
