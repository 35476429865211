import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./Panel.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";


interface Props{
  className?: string,
  color?: string,
  tag?: any,
  children? : React.ReactNode
}

const Panel = (props: Props) => {
  const {
    className,
    tag,
    color,
    children,
    ...attributes
  } = props;

  const panelClasses = classNames(className);

  let panelComponent = (
    <ThemeProvider theme={theme}>
      <Component colors={color} as={tag} {...attributes} className={panelClasses}>
        {children}
      </Component>
    </ThemeProvider>
  );

  return panelComponent;
};

Panel.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Panel.defaultProps = {
  tag: "div",
};

export default Panel;
export { Panel as CDBPanel };
