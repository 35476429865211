import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./FooterLink.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";

interface Props {
  children?: React.ReactNode,
  className?: string,
  href?: string,
  tag?: Function | string,
}

const FooterLink = (props: Props) => {
  const { children, className, href, tag, ...attributes } = props;
  const classes = classNames(className);

  return (
    <ThemeProvider theme={theme}>
      <Component as={(tag as unknown) as undefined} data-test="footer-link" {...attributes} className={classes}>
        <a href={href}>{children}</a>
      </Component>
    </ThemeProvider>
  );
};

FooterLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

FooterLink.defaultProps = {
  tag: "footerLink",
};

export default FooterLink;
export { FooterLink as CDBFooterLink };
