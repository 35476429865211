import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import PropTypes from 'prop-types';
import {
  getYear,
  getMonthHuman,
  getDate,
  getDaysInMonth,
} from '@wojtekmaj/date-utils';

import Input from './Input';

import { isMaxDate, isMinDate } from '../shared/propTypes';
import { safeMin, safeMax } from '../shared/utils';

interface Props{
  ariaLabel?: string,
  className?: string,
  disabled?: boolean,
  itemRef?:  (ele: HTMLElement, name: string) => void
  maxDate?: Date,
  minDate?: Date,
  month?: number,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>,
  placeholder?: string,
  required?: boolean,
  showLeadingZeros?: boolean,
  value?: number,
  year: number,
  autofocus? : any
  getPlaceholder?: any
}

export default function DayInput({
  maxDate,
  minDate,
  month,
  year,
  ...otherProps
}: Props) {
  const currentMonthMaxDays = (() => {
    if (!month) {
      return 31;
    }

    return getDaysInMonth(new Date(year, month - 1, 1));
  })();

  function isSameMonth(date) {
    return date && year === getYear(date) && month === getMonthHuman(date);
  }

  const maxDay = safeMin(currentMonthMaxDays, isSameMonth(maxDate) && getDate(maxDate));
  const minDay = safeMax(1, isSameMonth(minDate) && getDate(minDate));

  return (
    <Input
        max={maxDay}
      min={minDay}
      name="day"
      {...otherProps}    />
  );
}

DayInput.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  itemRef: PropTypes.func,
  maxDate: isMaxDate,
  minDate: isMinDate,
  month: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showLeadingZeros: PropTypes.bool,
  value: PropTypes.number,
  year: PropTypes.number,
  autoFocus: PropTypes.any,
  getPlaceholder: PropTypes.any
};
