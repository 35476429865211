import styled from "styled-components";

export const StyledComponent = styled.span`
  .suggestions {
    list-style: none;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 100;
    padding-left: 0;
    overflow-y: auto;
    list-style-type: none;
    background: #fff;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .suggestions li {
    padding: 12px 15px;
    font-size: 0.875rem;
    cursor: pointer;
  }
  .no-suggestions {
    list-style: none;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 100;
    padding-left: 0;
    overflow-y: auto;
    list-style-type: none;
    background: #fff;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .no-suggestions em {
    padding: 12px 15px;
    font-size: 0.87rem;
  }
`;
