import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { SidebarContext, SidebarContextType } from '../Sidebar'
import styled, { ThemeProvider, css } from 'styled-components'
import { theme } from '../../../theme'
import tinycolor from 'tinycolor2'

const CTA = styled.div<{
  mode: string
  backgroundColor: string
  textColor: string
  children: any
}>`
  position: relative;
  ${({ mode }) => (mode === 'light' ? css`` : css``)}
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background: ${(props) => props.backgroundColor};
  margin: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  padding: 15px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  * {
    box-sizing: border-box;
  }
  p {
    font-weight: 200;
    font-size: 12px;
    color: ${(props) => props.textColor};
  }
  .cta-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .close-icon {
    position: absolute;
    top: 12px;
    width: 17.5px;
    right: 12px;
    height: 17.5px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover,
    &:focus {
      background: ${(props) => props.textColor};
      svg {
        fill: ${(props) => props.backgroundColor};
      }
    }
    svg {
      width: 12.5px;
      height: 12.5px;
      fill: ${(props) => props.textColor};
    }
  }
`

interface Props {
  mode?: string
  image?: React.ReactNode // Changed this from Node
  text?: string
  children?: React.ReactNode[]
  backgroundColor?: string
  textColor?: any
  theme?: any
  className?: string
}

const SidebarCTA = (propss: Props) => {
  const {
    mode,
    children,
    image,
    text,
    backgroundColor,
    textColor,
    ...props
  } = propss
  const [show, setShow] = useState(true)
  const { toggled } = useContext<SidebarContextType>(SidebarContext)

  const color = tinycolor(backgroundColor)
  const CTABackgroundColor =
    mode === 'dark' ? color.lighten(10).toString() : color.darken(10).toString()

  console.log(CTABackgroundColor)
  return (
    <ThemeProvider theme={theme}>
      {!toggled && show && (
        <CTA
          mode={mode}
          backgroundColor={CTABackgroundColor}
          textColor={textColor}
          {...props}
        >
            <i className='close-icon' onClick={() => setShow(false)}>
              <svg
                className='w-4 h-4'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clip-rule='evenodd'
                ></path>
              </svg>
            </i>
            {children ? (
              { children }
            ) : (
              <div className='cta-content'>
                {image}
                <p>{text}</p>
              </div>
            )}
        </CTA>
      )}
    </ThemeProvider>
  )
}

SidebarCTA.propTypes = {
  mode: PropTypes.oneOf(['light', 'dark']),
  image: PropTypes.any, // PropTypes.node,
  text: PropTypes.string,
  children: PropTypes.any // PropTypes.node
}
SidebarCTA.defaultProps = {
  mode: 'dark'
}
export default SidebarCTA
export { SidebarCTA as CDBSidebarCTA }
