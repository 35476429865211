import styled from "styled-components";
import { colorStyle } from "styled-system";

export const Component = styled.div`
	${colorStyle}
	padding:20px;
	width:300px;
	border:1px #ccc solid;
	background-color: #ffffff;
	background-clip: border-box;
`;
