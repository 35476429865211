import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./PaneImage.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";

interface Props {
  className?: string,
  src?: string,
  height?: string,
  width?: string,
  size?: string,
  tag?: any
}

const PaneImage = (props: Props) => {

  const {
    className,
    tag,
    size,
    src,
    width,
    height,
    ...attributes
  } = props;

  const paneImageClasses = classNames(className);

  let paneImageComponent = (
    <ThemeProvider theme={theme}>
      <Component as={tag} src={src} width={width} height={height} size={size} {...attributes} className={paneImageClasses} />
    </ThemeProvider>
  );

  return paneImageComponent;
};

PaneImage.defaultProps = {
  tag: "img",
  size: "md",
};

PaneImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.oneOf([
    "lg",
    "md",
    "sm",
    "xs",
  ]),
};


export default PaneImage;
export { PaneImage as CDBPaneImage };
