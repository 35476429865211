import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'


interface Props {
  fill?: string, 
  chosenFill?: any,
  answers?: any[]
}

const RadioGroup = (props: Props) => {
  let [state, setState] = useState({
    answers: props.answers.map((answer) => ({
      answer,
      chosen: false,
      hovered: false,
      moused: false
    }))
  })
  const { fill, chosenFill, ...attributes } = props

 

  const handleClick = (e, index) => {
    e.preventDefault()
    return setState((state) => ({
      answers: state.answers.map(({ ...rest }, sIndex) => ({
        ...rest,
        moused: false,
        chosen: index === sIndex ? !state.answers[index].chosen : false
      }))
    }))
  }
  const handleMouseDown = (e, index) => {
    e.preventDefault()

    return setState((state) => ({
      answers: state.answers.map(({ ...rest }, sIndex) => ({
        ...rest,
        moused: index === sIndex ? true : false
      }))
    }))
  }
  const handleMouseEnter = (e, index) => {
    e.preventDefault()

    return setState((state) => ({
      answers: state.answers.map(({ ...rest }, sIndex) => ({
        ...rest,
        hovered: index === sIndex ? true : false
      }))
    }))
  }
  const handleMouseLeave = (e, index) => {
    e.preventDefault()

    return setState((state) => ({
      answers: state.answers.map(({ hovered, ...rest }, sIndex) => ({
        ...rest,
        hovered: index === sIndex ? false : hovered
      }))
    }))
  }

  const radioGroupStyle : React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
  const radioBtnContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '1rem',
    padding: '1rem'
  }
  const radioBtnInputStyle: React.CSSProperties = {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0
  }
  const radioBtnUncheckedStyle = {
    border: `.2rem solid ${fill}`
  }
  const radioBtnCheckedStyle = {
    backgroundColor: fill,
    border: `.2rem solid ${chosenFill}`,
    boxShadow: `0 0 0 .2rem ${fill}`
  }
  const radioBtnHoveredStyle = {
    boxShadow: `0 0 0 .4rem ${fill}75`,
    border: `.2rem solid ${fill}`
  }
  const radioBtnMousedStyle = {
    border: `.2rem solid ${fill}75`
  }
  const radioBtnStyle = {
    display: 'inline-block',
    height: '.9rem',
    width: '.9rem',
    borderRadius: '50%',
    marginRight: '1rem',
    transition: '.15s ease-in-out'
  }

  return (
    <ThemeProvider theme={theme}>
      <div id='radio-group' style={radioGroupStyle} {...attributes}>
        {state.answers.map(({ answer, hovered, chosen, moused }, index) => {
          return (
            <label
              key={index}
              onClick={(e) => handleClick(e, index)}
              onMouseEnter={(e) => handleMouseEnter(e, index)}
              onMouseLeave={(e) => handleMouseLeave(e, index)}
              onMouseDown={(e) => handleMouseDown(e, index)}
              style={radioBtnContainerStyle}
            >
              <input
                type='radio'
                value={answer}
                name={answer}
                checked={chosen}
                onChange={handleClick as React.ChangeEventHandler<HTMLInputElement>}
                style={radioBtnInputStyle}
              />
              <div
                style={{
                  ...radioBtnStyle,
                  ...(chosen
                    ? radioBtnCheckedStyle
                    : moused
                      ? radioBtnMousedStyle
                      : hovered
                        ? radioBtnHoveredStyle
                        : radioBtnUncheckedStyle)
                }}
              />
              {answer}
            </label>
          )
        })}
      </div>
    </ThemeProvider>
  )
}

export default RadioGroup

export { RadioGroup as CDBRadioGroup }
