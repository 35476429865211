import React, { useContext, useRef } from "react";
import { StepperContext } from "./Stepper";
import styled, { css } from "styled-components";


interface Props {
  direction?: string
  size?: number
  background?: string
  mainColor?: string
  status?: string
  dividerStepCompleteColor?: string
  dividerStepIncompleteColor?: string
  showTitle?: boolean
  showIndex?: boolean
  activeColor?: string
  completeColor?: string
  incompleteColor?: string
}

const Component = styled.div<Props>`
  display: flex;
  ${({ direction, size, showTitle, showIndex }) =>
    direction === "vertical" &&
    css`
      width: ${size}px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: ${(!showTitle && !showIndex )&& "10px"};
      span {
        margin: 2px 0;
        border-radius: 6px;
        transition: 0.4;
      }
      span:nth-child(1) {
        width: 6px;
        height: 6px;
      }
      span:nth-child(2) {
        width: 6px;
        height: 50px;
      }
    `}
  ${({ direction }) =>
    direction === "horizontal" &&
    css`
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      span {
        margin: 0 2px;
        border-radius: 6px;
        transition: 0.4;
      }
      span:nth-child(1) {
        width: 6px;
        height: 6px;
      }
      span:nth-child(2) {
        width: 50px;
        height: 6px;
      }
    `}
  ${({ status, dividerStepCompleteColor, completeColor }) =>
    status === "prev" &&
    css`
      span:nth-child(1) {
        background-color: ${ dividerStepCompleteColor ? dividerStepCompleteColor : (completeColor ? completeColor :  "#666666")};
      }
      span:nth-child(2) {
        background-color: ${ dividerStepCompleteColor ? dividerStepCompleteColor : (completeColor ? completeColor :  "#666666")};
      }
    `};
  ${({ status, dividerStepCompleteColor, dividerStepIncompleteColor, activeColor }) =>
    status === "active" &&
    css`
      span:nth-child(1) {
        background-color: ${ dividerStepCompleteColor ? dividerStepCompleteColor : (activeColor ? activeColor :  "#666666")};
      }
      span:nth-child(2) {
        background-color: ${ dividerStepIncompleteColor ? dividerStepIncompleteColor : (activeColor ? activeColor :  "#cccccc")};
      }
    `};
  ${({ status, dividerStepIncompleteColor,  incompleteColor }) =>
    status === "next" &&
    css`
      span:nth-child(1) {
        background-color: ${ dividerStepIncompleteColor ? dividerStepIncompleteColor : (incompleteColor ? incompleteColor :  "#cccccc")};
      }
      span:nth-child(2) {
        background-color: ${ dividerStepIncompleteColor ? dividerStepIncompleteColor : (incompleteColor ? incompleteColor :  "#cccccc")};
      }
    `}
`;

interface Props {
  status?: string
  activeColor?: string
  completeColor?: string
  incompleteColor?: string
}

const StepperDivider = ({ 
  status, 
  activeColor,
  completeColor,
  incompleteColor} : Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { direction, mainColor, stepSize, 
    showTitle,
    showIndex,
    dividerStepCompleteColor,
    dividerStepIncompleteColor,
  } =
    useContext(StepperContext);

  return (
    <Component
      direction={direction}
      mainColor={mainColor}
      background={mainColor}
      ref={containerRef}
      size={stepSize}
      status={status}
      showTitle={showTitle}
      showIndex={showIndex}
      dividerStepCompleteColor={dividerStepCompleteColor}
      dividerStepIncompleteColor={dividerStepIncompleteColor}
      activeColor={activeColor}
      completeColor={completeColor}
      incompleteColor={incompleteColor}
    >
      <span></span>
      <span></span>
    </Component>
  );
};

export default StepperDivider;
