import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { omit } from "../utils";
import { Component } from "./TabContent.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";
// import { contextType } from "focus-trap-react";

interface Props {
  activeItem?: any;
  className?: string;
  children?: React.ReactNode
}

export const TabContext = createContext<{
  activeItemId: any;
}>({
  activeItemId: null,
});

const TabContent = (props: Props) => {
  const { className } = props;

  const [state, setState] = useState({
    activeItem: props.activeItem
  });

  // const getChildContext = () => {
  //   const { activeItem } = state;
  //   return {
  //     activeItemId: activeItem,
  //   };
  // };

  // function getDerivedStateFromProps(nextProps, prevState) {
  //   return prevState.activeItem !== nextProps.activeItem
  //     ? { activeItem: nextProps.activeItem }
  //     : null;
  // }

  const attributes = omit(props, Object.keys(propTypes));

  const classes = classNames("tab-content", className);
  return (
    <ThemeProvider theme={theme}>
      <TabContext.Provider value={{
        activeItemId: props.activeItem
      }}>
        <Component>
          <div
            data-test="tabContent"
            {...(attributes as object)}
            className={classes}
          />
        </Component>
      </TabContext.Provider>
    </ThemeProvider>
  );
};

TabContent.propTypes = {
  activeItem: PropTypes.any,
  className: PropTypes.string,
};

const propTypes = {
  activeItem: PropTypes.any,
  className: PropTypes.string,
};

export default TabContent;
export { TabContent as CDBTabContent };
