import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Component } from './TabPane.style'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'
import { TabContext } from '../TabContent/TabContent'

interface Props {
  className?: string,
  tabId?: any,
  activeItemId?: any
  children?: React.ReactNode,
  role?: string
}

const TabPane = (props: Props) => {
  const { className, tabId, ...attributes } = props
  const { activeItemId } = useContext(TabContext)
  const classes = classNames(
    'tab-pane',
    { active: tabId === activeItemId },
    className
  )

  const activeId = tabId === activeItemId ? true : false
  return (
    <ThemeProvider theme={theme}>
      <Component>
        <TransitionGroup>
          <CSSTransition
            timeout={300}
            key={tabId}
            in={activeId}
            appear={true}
            classNames='fade'
          >
            <div
              data-test='tab-pane'
              {...attributes}
              className={classes}
              role='tabpanel'
            />
          </CSSTransition>
        </TransitionGroup>
      </Component>
    </ThemeProvider>
  )
}


TabPane.propTypes = {
  className: PropTypes.string,
  tabId: PropTypes.any
}

export default TabPane
export { TabPane as CDBTabPane }
