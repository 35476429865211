import styled from "styled-components";

export const Component = styled.div`
  /* appear - on page load */
  .fade-appear {
    display: none;
    opacity: 0;
    z-index: 1;
  }
  .fade-appear.fade-appear-active {
    opacity: 1;
    display: block;
    transition: opacity 300ms linear;
  }

  /* enter */
  .fade-enter {
    display: none;
    opacity: 0;
    z-index: 1;
  }
  .fade-enter.fade-enter-active {
    display: block;
    opacity: 1;
    transition: opacity 300ms linear 300ms;
  }

  /* exit */
  .fade-exit {
    display: block;
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    display: none;
    opacity: 0;
    transition: opacity 300ms linear;
  }
  .fade-exit-done {
    opacity: 0;
    display: none;
  }
`;
