import React from 'react';
import PropTypes from 'prop-types';
import { getYear } from '@wojtekmaj/date-utils';

import Input from './Input';

import { isMaxDate, isMinDate, isValueType } from '../shared/propTypes';
import { safeMax, safeMin } from '../shared/utils';

interface Props {
  ariaLabel?: string,
  className?: string,
  disabled?: boolean,
  itemRef?:  (ele: HTMLElement, name: string) => void
  maxDate?: Date,
  minDate?: Date,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>,
  placeholder?: string,
  required?: boolean,
  value?: number,
  valueType: 'century' | 'decade'| 'year'| 'month'| 'day',
  autoFocus?: any
  getPlaceholder?: any
}

export default function YearInput({
  maxDate,
  minDate,
  placeholder = '____',
  valueType,
  ...otherProps
}: Props) {
  const maxYear = safeMin(275760, maxDate && getYear(maxDate));
  const minYear = safeMax(1, minDate && getYear(minDate));

  const yearStep = (() => {
    if (valueType === 'century') {
      return 10;
    }

    return 1;
  })();

  return (
    <Input
      max={maxYear}
      min={minYear}
      name="year"
      placeholder={placeholder}
      step={yearStep}
      {...otherProps}
    />
  );
}

YearInput.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  itemRef: PropTypes.func,
  maxDate: isMaxDate,
  minDate: isMinDate,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.number,
  valueType: isValueType,
  autoFocus: PropTypes.any,
  getPlaceholder: PropTypes.any
};
