import React, { useState, useRef, useEffect } from "react";
import { Component } from "./FileUploader.style";
import CDBProgress from "../Progress";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

const FileUploader = (props) => {
  const fileSelect = useRef<HTMLInputElement>(null); //.file-upload
  const fileDrag = useRef<HTMLLabelElement>(null); // .file-drag
  const submitButton = useRef<HTMLElement>(null); // .submit-button
  const messageBar = useRef<HTMLDivElement>(null); // .messages
  const start = useRef<HTMLDivElement>(null); // #start
  const response = useRef<HTMLDivElement>(null); // #response
  const notImage = useRef<HTMLDivElement>(null); // #not-image
  const fileImage = useRef<HTMLImageElement>(null); // #file-image
  const fileUploadForm = useRef<HTMLFormElement>(null); // #file-upload-form
  const [value, setValue] = useState(0); // #file-progress-value

  useEffect(() => {
    if (window.File && window.FileList && window.FileReader) {
      fileUpload();
    } else {
      fileDrag.current!.style.display = "none";
    }
  });
  const fileUpload = () => {
    fileSelect.current!.addEventListener("change", fileSelectHandler, false);
    var xhr = new XMLHttpRequest();
    if (xhr.upload) {
      fileDrag.current!.addEventListener("dragover", fileDragHover, false);
      fileDrag.current!.addEventListener("dragleave", fileDragHover, false);
      fileDrag.current!.addEventListener("drop", fileSelectHandler, false);
    }
  };
  const fileDragHover = (e) => {
    e.stopPropagation();
    e.preventDefault();
    fileDrag.current!.className =
      e.type === "dragover" ? "hover" : "modal-body file-upload";
  };

  const fileSelectHandler = (e) => {
    var files = e.target.files || e.dataTransfer.files;
    fileDragHover(e);
    for (var i = 0, f; (f = files[i]); i++) {
      parseFile(f);
      uploadFile(f);
    }
  };
  const output = (msg) => {
    messageBar.current!.innerHTML = msg;
  };

  const parseFile = (file) => {
    output("<strong>" + encodeURI(file.name) + "</strong>");
    var imageName = file.name;
    var isGood = /\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
    if (isGood) {
      start.current!.classList.add("hidden");
      response.current!.classList.remove("hidden");
      notImage.current!.classList.add("hidden");
      fileImage.current!.classList.remove("hidden");
      fileImage.current!.src = URL.createObjectURL(file);
    } else {
      fileImage.current!.classList.add("hidden");
      notImage.current!.classList.remove("hidden");
      start.current!.classList.remove("hidden");
      response.current!.classList.add("hidden");
      fileUploadForm.current!.reset();
    }
  };

  const setProgressMaxValue = (e) => {
    if (e.lengthComputable) {
      (document.getElementById("file-progress") as any)!.max = e.total;
    }
  };
  const updateFileProgress = (e) => {
    if (e.lengthComputable) {
      setValue(e.loaded * (100/e.total));
    }
  };
  const uploadFile = (file) => {
    var xhr = new XMLHttpRequest(),
      fileSizeLimit = 1024;
    if (xhr.upload) {
      if (file.size <= fileSizeLimit * 1024 * 1024) {
        document.getElementById("file-progress")!.style.display = "inline";
        xhr.upload.addEventListener("loadstart", setProgressMaxValue, false);
        xhr.upload.addEventListener("progress", updateFileProgress, false);
        xhr.onreadystatechange = function (e) {
          if (xhr.readyState === 4) {
          }
        };
        xhr.open("POST", fileUploadForm.current!.action, true);
        xhr.setRequestHeader("X-File-Name", file.name);
        xhr.setRequestHeader("X-File-Size", file.size);
        xhr.setRequestHeader("Content-Type", "multipart/form-data");
        xhr.send(file);
      } else {
        output("Please upload a smaller file (< " + fileSizeLimit + " MB).");
      }
    }
  };

  const { ...attributes } = props;

  let fileUploadComponent = (
    <ThemeProvider theme={theme}>
      <Component {...attributes}>
        <form className="uploader" ref={fileUploadForm}>
          <input
            id="file-upload"
            ref={fileSelect}
            type="file"
            name="fileUpload"
            accept="image/*"
          />
          <label htmlFor="file-upload" id="file-drag" ref={fileDrag}>
            <img
              id="file-image"
              ref={fileImage}
              src="#"
              alt="Preview"
              className="hidden"
            />
            <div id="start" ref={start}>
              <i className="fa fa-download" aria-hidden="true"></i>
              <div>Select a file or drag here</div>
              <div id="notimage" ref={notImage} className="hidden">
                Please select an image
              </div>
              <span
                id="file-upload-btn"
                ref={submitButton}
                className="btn btn-primary"
              >
                Select a file
              </span>
            </div>
            <div id="response" ref={response} className="hidden">
              <div id="messages" ref={messageBar}></div>
              <CDBProgress id="file-progress" text={`${value}%`} value={value}></CDBProgress>
            </div>
          </label>
        </form>
      </Component>
    </ThemeProvider>
  );
  return fileUploadComponent;
};

export default FileUploader;

export { FileUploader as CDBFileUploader };