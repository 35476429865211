import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CDBTableHeader from '../Table/TableHeader'
import CDBTableBody from '../Table/TableBody'
import CDBIcon from '../Icon'
import CDBBadge from '../Badge'
import { Component, Table as StyledTable } from './EditableTable.style'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'

interface Props {
  autoWidth?: boolean
  bordered?: boolean
  borderless?: boolean
  btn?: boolean
  data?: any
  columns?: any
  className?: string
  dark?: boolean
  fixed?: boolean
  hover?: boolean
  maxHeight?: string
  responsive?: boolean
  responsiveLg?: boolean
  responsiveMd?: boolean
  responsiveSm?: boolean
  responsiveXl?: boolean
  scrollY?: boolean
  small?: boolean
  striped?: boolean
  theadColor?: string
  wrapperClassName?: string
}

const EditableTable = (props: Props) => {
  const {
    autoWidth,
    bordered,
    borderless,
    btn,
    data,
    columns,
    className,
    dark,
    fixed,
    hover,
    maxHeight,
    responsive,
    responsiveLg,
    responsiveMd,
    responsiveSm,
    responsiveXl,
    scrollY,
    small,
    striped,

    theadColor,
    wrapperClassName,
    ...attributes
  } = props

  const [tableData] = useState(data)
  const [clicked, setClicked] = useState(true)

  const tableClasses = classNames(
    'table',
    autoWidth && 'w-auto',
    bordered && 'table-bordered',
    borderless && 'table-borderless',
    btn && 'btn-table',
    fixed && 'table-fixed',
    hover && 'table-hover',
    striped && 'table-striped',
    small && 'table-sm',
    className
  )

  const wrapperClasses = classNames(
    {
      'table-dark': dark,
      'table-responsive': responsive,
      'table-responsive-sm': responsiveSm,
      'table-responsive-md': responsiveMd,
      'table-responsive-lg': responsiveLg,
      'table-responsive-xl': responsiveXl,
      'table-wrapper-scroll-y': scrollY
    },
    wrapperClassName
  )

  const wrapperStyles = { maxHeight }

  const add = () => {
    const test = data[0].map((sample) => {
      return 'Example'
    })
    tableData.push(test)
    setClicked(!clicked)
  }
  const del = (e) => {
    e.target.parentNode.parentNode.remove()
  }

  let editableTableComponent = (
    <ThemeProvider theme={theme}>
      <Component
        data-test="table"
        className={wrapperClasses}
        style={wrapperStyles}
        scrollY={scrollY}
        autoWidth={autoWidth}
      >
        <div onClick={add}>
          <CDBIcon className='float-right m-2 text-success' fas icon='plus' />
        </div>
        <StyledTable {...attributes} className={tableClasses}>
          <CDBTableHeader color={''}>
            {columns && (
              <tr>
                {columns.map((column, index) => {
                  return <th key={index}>{column}</th>
                })}
                <th>Remove</th>
              </tr>
            )}
          </CDBTableHeader>
          <CDBTableBody color={''}>
            {data &&
              tableData.map((row, index) => {
                return (
                  <tr key={index}>
                    {row.map((data, i) => {
                      return (
                        <td
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                          key={i}
                        >
                          {data}
                        </td>
                      )
                    })}
                    <td className='text-center'>
                      <div onClick={del}>
                        <CDBBadge
                          flat
                          className='bg-danger font-weight-bold'
                        >
                          X
                        </CDBBadge>
                      </div>
                    </td>
                  </tr>
                )
              })}
          </CDBTableBody>
        </StyledTable>
      </Component>
    </ThemeProvider>
  )
  return editableTableComponent
}

EditableTable.propTypes = {
  autoWidth: PropTypes.bool,
  bordered: PropTypes.bool,
  borderless: PropTypes.bool,
  btn: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  className: PropTypes.string,
  dark: PropTypes.bool,
  fixed: PropTypes.bool,
  hover: PropTypes.bool,
  maxHeight: PropTypes.string,
  responsive: PropTypes.bool,
  responsiveLg: PropTypes.bool,
  responsiveMd: PropTypes.bool,
  responsiveSm: PropTypes.bool,
  responsiveXl: PropTypes.bool,
  scrollY: PropTypes.bool,
  small: PropTypes.bool,
  striped: PropTypes.bool,
  theadColor: PropTypes.string,
  wrapperClassName: PropTypes.string
}

export default EditableTable
export { EditableTable as CDBEditableTable }
