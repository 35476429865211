import React, { useState, createContext } from "react";

export const DropDownContext = createContext({});

export const DropDownProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DropDownContext.Provider
      value={{
        isOpen: [isOpen, setIsOpen],
      }}
    >
      {props.children}
    </DropDownContext.Provider>
  );
};
