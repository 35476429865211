import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Component } from './CardImage.style';
import View from '../../View';
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";


interface Props {
    cascade?: boolean,
    children?: React.ReactNode,
    className?: string,
    hover?: boolean,
    overlay?: string,
    src?: string,
    tag?: Function | string,
    top?: boolean,
    waves?: boolean,
    zoom?: boolean,
    alt?: string
}

const CardImage = (props: Props) => {

    const { cascade, className, hover, overlay, src, tag, top, waves, zoom, ...attributes } = props;

    const classes = classNames(top && 'card-img-top', className);


    const innerContent = <Component data-test='card-image' as={(tag as unknown) as undefined} src={src} {...attributes} className={classes} />;

    if (src) {
        return (
            <ThemeProvider theme={theme}>
                <View zoom={zoom} hover={hover} cascade={cascade}>
                    <div style={{ touchAction: 'unset' }}>
                        {innerContent}
                    </div>
                </View>
            </ThemeProvider>
        );
    }
    return (
        <ThemeProvider theme={theme}>
            <div>{innerContent}</div>
        </ThemeProvider>
    );
};

CardImage.propTypes = {
    cascade: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    hover: PropTypes.bool,
    overlay: PropTypes.string,
    src: PropTypes.string,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    top: PropTypes.bool,
    waves: PropTypes.bool,
    zoom: PropTypes.bool
};

CardImage.defaultProps = {
    tag: 'img',
    overlay: 'white-slight',
    waves: true,
    hover: false,
    cascade: false,
    zoom: false
};

export default CardImage;
export { CardImage as CDBCardImage };