import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Component } from "./Checkbox.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props {
	tag?: string,
	className?: string,
	disabled?: boolean,
}

const Checkbox = (props: Props) => {
	const {
		tag,
		className,
		disabled,
		...attributes
	} = props;

	const [active, setActive] = useState(false);

	const classes = classNames({ disabled, active }, className);

	let checkboxComponent = (
		<ThemeProvider theme={theme}>
			<Component
				data-test="input"
				className={classes}
				onClick={() => setActive(!active)}
				as={(tag as unknown) as undefined} {...attributes}
			>
				<div></div>
			</Component>
		</ThemeProvider>
	);

	return checkboxComponent;
};

Checkbox.propTypes = {
	tag: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
	tag: "div",
};

export default Checkbox;
export { Checkbox as CDBCheckbox };
