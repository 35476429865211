import styled, { css } from "styled-components";

export const Component = styled.span<{
  size: string
  color:  "primary"|  "secondary"|  "success"|  "danger"|  "warning"|  "info"|  "dark"
}>`
  ${(props) =>
    props.size === "lg"
      ? css`
          width: 100px;
          height: 100px;
        `
      : props.size === "sm"
        ? css`
          width: 50px;
          height: 50px;
        `
        : css`
          width: 75px;
          height: 75px;
        `}

  .CircularProgressbar {
    width: inherit;
    vertical-align: middle;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: ${(props) =>
    props.color === "primary"
      ? "#3e98c7"
      : props.color === "secondary"
        ? "#7356BF"
        : props.color === "danger"
          ? "#E11900"
          : props.color === "warning"
            ? "#66512C"
            : props.color === "info"
              ? "#17A2B8"
              : props.color === "success"
                ? "#05944F"
                : "#000000"};
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
    stroke-linecap: round;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: ${(props) =>
    props.color === "primary"
      ? "#3e98c7"
      : props.color === "secondary"
        ? "#7356BF"
        : props.color === "danger"
          ? "#E11900"
          : props.color === "warning"
            ? "#66512C"
            : props.color === "info"
              ? "#17A2B8"
              : props.color === "success"
                ? "#05944F"
                : "#000000"};
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  .CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6;
  }

  .CircularProgressbar.CircularProgressbar-inverted
    .CircularProgressbar-background {
    fill: #3e98c7;
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }
`;
