import styled, { css } from "styled-components";

export const Component = styled.p<{
  small: boolean
  muted: boolean
}>`
	margin:0px;
	font-size:0.9em;
	letter-spacing:0.05em;

	${(props) =>
    props.small
      ? css`
          font-size:0.8em;
        `
      : css``};
	${(props) =>
    props.muted
      ? css`
          opacity:0.6;
        `
      : css``};
`;
