import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CDBIcon from '../Icon'
import { Component, Options, Div } from './Multiselect.style'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'

interface Props {
  tag?: string
  submit?: boolean
  search?: boolean
  color?: string
  options?: any[]
  selected?: string
  className?: string
  limitedOptions?: boolean
  CDBBadge?: any
  optionClassName?: string
  text?: any
  showing?: any
}

const Multiselect = (props: Props) => {
  const {
    tag,
    text,
    showing,
    color,
    submit,
    options,
    search,
    selected,
    className,
    limitedOptions,
    optionClassName,
    CDBBadge,
    ...attributes
  } = props

  const [show, setShow] = useState(false)
  const [active] = useState([selected])
  const [input, setInput] = useState('')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const multiSelectClasses = classNames(className)
  const optionClasses = classNames(show ? 'display' : '', optionClassName)

  const display = () => {
    ; (search || submit) && inputRef.current!.focus()
    setShow(!show)
    console.log('tampered')
  }

  const addActive = (text) => {
    let condition = true
    for (let i = 0; i < active.length; i++) {
      text === active[i] && (condition = false)
    }
    condition && active.push(text)
  }

  const setShowing = (option) => {
    limitedOptions && (option.showing = false)
    addActive(option.text)
    setShow(!show)
    setInput('')
  }

  const close = (item, index) => {
    active.splice(index, 1)
    for (let i = 0; i < updatedOptions.length; i++) {
      item === updatedOptions[i].text && (updatedOptions[i].showing = true)
    }
  }

  const inptUpdate = (e) => {
    setInput(e.target.value)
    setShow(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    submit && addActive(input)
    setInput('')
  }

  const updatedOptions: any = options.filter((item: any) => {
    return item.text.toLowerCase().includes(input.toLowerCase())
  })

  const useOutsideClose = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  let multiselectComponent = (
    <ThemeProvider theme={theme}>
      <Component
        data-test='select'
        className={multiSelectClasses}
        ref={wrapperRef}
        onClick={useOutsideClose(wrapperRef) as unknown as React.MouseEventHandler<HTMLDivElement>}
        as={(tag as unknown) as undefined}
        {...attributes}
      >
        <Div onClick={display}>
          {active.map((item, index) => {
            return (
              <CDBBadge key={index} flat colors={color}>
                {item}{' '}
                <div onClick={() => close(item, index)}>
                  {' '}
                  <CDBIcon fas icon='times' />
                </div>
              </CDBBadge>
            )
          })}
          {(search || submit) && (
            <form onSubmit={handleSubmit}>
              <input
                placeholder='Tag'
                ref={inputRef}
                value={input}
                onChange={inptUpdate}
                type='text'
              />
            </form>
          )}
        </Div>
        <Options className={optionClasses}>
          {options &&
            (search ? updatedOptions : options).map((optionType, index) => {
              const { text, showing } = optionType
              return (
                showing !== false && (
                  <div key={index} onClick={() => setShowing(optionType)}>
                    <span>{text}</span>
                  </div>
                )
              )
            })}
        </Options>
      </Component>
    </ThemeProvider>
  )

  return multiselectComponent
}

Multiselect.propTypes = {
  tag: PropTypes.string,
  submit: PropTypes.bool,
  search: PropTypes.bool,
  color: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.string,
  className: PropTypes.string,
  limitedOptions: PropTypes.bool,
  optionClassName: PropTypes.string
}

Multiselect.defaultProps = {
  tag: 'div',
  color: 'primary'
}

export default Multiselect
export { Multiselect as CDBMultiselect }
