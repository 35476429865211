import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CDBCloseIcon from "../CloseIcon";
import CDBIcon from "../Icon";
import { Component } from "./Notification.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props {
  autohide?: number,
  bodyClassName?: string,
  bodyColor?: string,
  className?: string,
  closeClassName?: string,
  fade?: boolean,
  hideMessage?: boolean,
  icon?: string,
  iconClassName?: string,
  message?: string,
  position?: string,
  show?: boolean,
  tag?: Function | string,
  text?: string,
  title?: string,
  titleClassName?: string,
  titleColor?: string,
  iconBoxClassName?: any,
  color?: any,
}

const Notification = (props: Props) => {

  const {
    tag,
    autohide,
    className,
    show,
    fade,
    hideMessage,
    message,
    bodyClassName,
    icon,
    iconClassName,
    position,
    title,
    titleClassName,
    text,
    closeClassName,
    iconBoxClassName,
    color,
    ...attributes
  } = props;

  const [componentState, setComponentState] = useState(show ? "show" : "hide");
  const [componentMessageState, setComponentMessageState] = useState(!hideMessage);

  useEffect(() => {
    if (autohide && autohide > 0) {
      hide(autohide * 1000);
    }
  }, [autohide]);

  const hideMsg = (e) => {
    setComponentMessageState(!componentMessageState);
  };

  const hide = (time) => {
    if (typeof time === "object") {
      time = 0;
    }

    setTimeout(() => {
      setTimeout(() => {
        setTimeout(() => {
          setComponentState("hide")
        }, 150);
      }, 1000);
      setComponentState("fade")
    }, time)

  };

  const classes = classNames(
    "toast",
    fade && "fade",
    componentState,
    className
  );

  const iconClassNames = classNames(iconClassName);
  const iconBoxClassNames = classNames("icon-box", iconBoxClassName);
  const bodyClasses = classNames("body", bodyClassName);
  const closeClasses = classNames("close-icon", closeClassName);

  const notificationComp = (
    <ThemeProvider theme={theme}>
      <Component
        as={(tag as unknown) as undefined}
        data-test="notification"
        color={color}
        position={
          position === "top-right"
            ? "topRight"
            : position === "top-left"
              ? "topLeft"
              : position === "bottom-right"
                ? "bottomRight"
                : position === "bottom-left"
                  ? "bottomLeft"
                  : position
        }
        {...attributes}
        className={classes}
      >
        <div className="toast-container">
          <div className={iconBoxClassNames}>
            <CDBIcon icon={icon!} size="lg" className={iconClassNames} />
          </div>
          <div className={bodyClasses}>
            <strong className="ml-3">{title}</strong>
            <small className="ml-3 small-text">{text}</small>
          </div>
          <CDBCloseIcon className={closeClasses} onClick={hide} />
          <div className="toggle">
            <span onClick={hideMsg}></span>
          </div>
        </div>
        {componentMessageState && (
          <div className="toast-message">{message}</div>
        )}
      </Component>
    </ThemeProvider>
  );

  return notificationComp;
}

Notification.propTypes = {
  autohide: PropTypes.number,
  bodyClassName: PropTypes.string,
  bodyColor: PropTypes.string,
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  fade: PropTypes.bool,
  hideMessage: PropTypes.bool,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  message: PropTypes.string,
  position: PropTypes.string,
  show: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  text: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  titleColor: PropTypes.string,
};

Notification.defaultProps = {
  icon: "square",
  tag: "div",
  closeClassName: "text-dark",
  hideMessage: false,
};

export default Notification;
export { Notification as CDBNotification };
