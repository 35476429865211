import React, { useState, forwardRef, useRef, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { SidebarContext, SidebarContextType } from "../Sidebar";
import {
  InnerMenu,
  SubMenuContainerLi,
  PopperInner,
} from "./SidebarSubMenu.style";
import Icon from "../../Icon";
import { ThemeProvider } from "styled-components";
import { theme } from "../../../theme";
import { usePopper } from "./SubMenuHooks";
import { SubMenuContent } from "./SubMenuContent";

interface Props {
  children?: React.ReactNode;
  icon?: string;
  iconClassName?: string;
  iconSize?: string;
  className?: string;
  title?: string;
  defaultOpen?: boolean;
  open?: boolean;
  suffix?: React.ReactNode;
  firstChild?: number;
  popperArrow?: number;
}

const SidebarSubMenu: React.FC<Props> = forwardRef<HTMLLIElement, Props>(
  (
    {
      children,
      icon,
      iconClassName,
      iconSize,
      className,
      title,
      defaultOpen = false,
      open: openControlled,
      suffix,
      firstChild,
      popperArrow,
      ...rest
    },
    ref
  ) => {
    const { toggled, textColor, backgroundColor } =
      useContext<SidebarContextType>(SidebarContext);
    const [open, setOpen] = React.useState(!!defaultOpen);
    const [openWhenCollapsed, setOpenWhenCollapsed] = React.useState(false);
    const [mounted, setMounted] = React.useState(false);

    const popperElRef = useRef<HTMLDivElement>(null);
    const referenceElement = useRef<HTMLDivElement>(null); // same as buttonRef
    const popperElement = useRef<HTMLDivElement>(null); // ? contentRef

    const timer = React.useRef<ReturnType<typeof setTimeout>>();

    React.useEffect(() => {
      setMounted(true);
    }, []);

    const { popperInstance } = usePopper({
      referenceElement,
      popperElement,
      firstChild,
    });

    const slideUp = () => {
      const target = popperElement.current;
      if (target) {
        target.style.display = "block";
        target.style.overflow = "hidden";
        target.style.height = "auto";
        const height = target.offsetHeight;
        target.style.height = "0px";
        target.offsetHeight;
        target.style.height = `${height}px`;

        timer.current = setTimeout(() => {
          target.style.overflow = "auto";
          target.style.height = "auto";
        }, 3);
      }
    };

    const slideDown = () => {
      const target = popperElement.current;
      if (target) {
        target.style.overflow = "hidden";
        target.style.height = `${target.offsetHeight}px`;
        target.offsetHeight;
        target.style.height = "0px";

        timer.current = setTimeout(() => {
          target.style.overflow = "auto";
          target.style.display = "none";
        }, 3);
      }
    };

    const handleSlideToggle = (): void => {
      if (!(firstChild && toggled)) {
        clearTimeout(Number(timer.current));
        const openValue = openControlled ?? open;
        openValue ? slideDown() : slideUp();
        typeof openControlled === "undefined" && setOpen(!open);
      }
    };

    const handleOnClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      // onClick?.(event);
      handleSlideToggle();
    };

    const handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
      // onKeyUp?.(event);
      if (event.key === "Enter") {
        handleSlideToggle();
      }
    };

    React.useEffect(() => {
      setTimeout(() => popperInstance?.update(), 3);
      if (toggled && firstChild === 0) {
        setOpenWhenCollapsed(false);
        // ? if its useful to close first level submenus on collapse sidebar uncomment the code below
        // setOpen(false);
      }
    }, [toggled, firstChild, popperInstance]);

    React.useEffect(() => {
      const handleTogglePopper = (target: Node) => {
        if (!openWhenCollapsed && referenceElement.current?.contains(target))
          setOpenWhenCollapsed(true);
        else if (
          !popperElement.current?.contains(target) &&
          openWhenCollapsed
        ) {
          setOpenWhenCollapsed(false);
        }
      };

      const handleDocumentClick = (event: MouseEvent) => {
        handleTogglePopper(event.target as Node);
      };

      const handleDocumentKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
          handleTogglePopper(event.target as Node);
        } else if (event.key === "Escape") {
          setOpenWhenCollapsed(false);
        }
      };

      const removeEventListeners = () => {
        document.removeEventListener("click", handleDocumentClick);
        document.removeEventListener("keyup", handleDocumentKeyUp);
      };

      removeEventListeners();

      if (toggled && firstChild === 0) {
        document.addEventListener("click", handleDocumentClick, false);
        document.addEventListener("keyup", handleDocumentKeyUp, false);
      }

      return () => {
        removeEventListeners();
      };
    }, [toggled, firstChild, openWhenCollapsed]);

    return (
      <ThemeProvider theme={theme}>
        <SubMenuContainerLi
          ref={ref}
          className={classNames(
            "pro-menu-item pro-sub-menu",
            className,
            {
              open: typeof open === "undefined" ? defaultOpen : open,
            },
            { toggled }
          )}
        >
          <InnerMenu
            {...rest}
            ref={referenceElement}
            onClick={handleOnClick}
            onKeyUp={handleOnKeyUp}
            role="button"
            tabIndex={0}
            className={classNames(
              "pro-inner-item",
              className,
              {
                active: typeof open === "undefined" ? defaultOpen : open,
              },
              { toggled }
            )}
            textColor={textColor!}
            backgroundColor={backgroundColor!}
          >
            {icon && (
              <Icon
                icon={icon}
                className={classNames(iconClassName, "side-icon")}
                size={iconSize}
              />
            )}

            <span className="pro-item-content">{title}</span>
            {suffix ? <span className="suffix-wrapper">{suffix}</span> : null}
            <span className="pro-arrow-wrapper">
              <span className="pro-arrow" />
            </span>
          </InnerMenu>

          {/* {firstChild && toggled && ( */}
          <SubMenuContent
            ref={popperElement}
            openWhenCollapsed={openWhenCollapsed}
            open={openWhenCollapsed ?? open}
            firstLevel={firstChild === 0}
            collapsed={toggled}
            defaultOpen={(openControlled && !mounted) || defaultOpen}
            className={classNames("pro-inner-list-item popper-element", {
              "has-arrow": popperArrow,
            })}
            backgroundColor={backgroundColor!}
          >
            <PopperInner
              className="popper-inner"
              ref={popperElRef}
              backgroundColor={backgroundColor!}
            >
              <ul>{children}</ul>
            </PopperInner>
            {popperArrow ? (
              <div className="popper-arrow" data-popper-arrow />
            ) : null}
          </SubMenuContent>
        </SubMenuContainerLi>
      </ThemeProvider>
    );
  }
);

SidebarSubMenu.propTypes = {
  children: PropTypes.any, //PropTypes.node,
  className: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  title: PropTypes.any, //PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  suffix: PropTypes.any, //PropTypes.node.isRequired,
  firstChild: PropTypes.number.isRequired,
  popperArrow: PropTypes.number.isRequired,
};

SidebarSubMenu.defaultProps = {
  iconSize: "md",
};

export default SidebarSubMenu;

export { SidebarSubMenu as CDBSidebarSubMenu };
