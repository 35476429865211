import styled from "styled-components";
import { colorStyle } from "styled-system";

export const Component = styled.div`
  ${colorStyle}
  
  .btn {
  	border-radius: 0px;
  }

`;
