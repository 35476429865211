import React from "react";
import PropTypes from "prop-types";
import { Component } from "./CircularProgress.style";
import { CircularProgressbar } from "react-circular-progressbar";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

interface Props {
  className?: string,
  max?: number,
  min?: number,
  value?: number,
  size?: string,
  color?:  "primary"|  "secondary"|  "success"|  "danger"|  "warning"|  "info"|  "dark"
  tag?: string,
  text?: string
}

const CircularProgress = (props: Props) => {
  const { value, text, max, min, color, tag, size, ...attributes } = props;
  let circularProgressComp = (
    <ThemeProvider theme={theme}>
      <Component size={size} color={color}>
        <CircularProgressbar
          value={value}
          maxValue={max}
          minValue={min}
          text={text}
          {...attributes}
        />
      </Component>
    </ThemeProvider>
  );
  return circularProgressComp;
};
CircularProgress.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  size: PropTypes.oneOf(["md", "sm", "lg"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "dark",
  ]),
  tag: PropTypes.string,
};

CircularProgress.defaultProps = {
  tag: "span",
  className: "",
  max: 100,
  min: 0,
  value: 0,
  size: "md",
  color: "dark",
};

export default CircularProgress;

export { CircularProgress as CDBCircularProgress };
